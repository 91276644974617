// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.make-a-payment-modal .ant-radio-wrapper {
  width: 100%;
  padding-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Ledger/modals/MakeAPayment/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".make-a-payment-modal .ant-radio-wrapper {\n  width: 100%;\n  padding-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { FC } from 'react';

import { Modal } from 'antd';

import './index.css';
import MakeAPaymentComponent from './MakeAPayment';
import AutoPaymentComponent from './AutoPayment';

interface MakeAPaymentProps {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  onPaymentSuccess: () => void;
}

const MakeAPayment: FC<MakeAPaymentProps> = ({ visible, onVisibleChange }) => {
  const dismiss = () => onVisibleChange(false);

  return (
    <Modal
      title="Choose Your Payment"
      footer={null}
      visible={visible}
      onCancel={dismiss}
      className={'make-a-payment-modal'}
    >
      {visible && (
        <div className={'mt-3'}>
          <MakeAPaymentComponent onPaymentSuccess={() => dismiss()} />

          <table className={'w-100'}>
            <tr>
              <td>
                <hr style={{ margin: '32px 0' }} />
              </td>
              <td style={{ width: '32px', color: '#999', textAlign: 'center' }}>
                or
              </td>
              <td>
                <hr style={{ margin: '32px 0' }} />
              </td>
            </tr>
          </table>

          <AutoPaymentComponent onPaymentSuccess={() => dismiss()} />
        </div>
      )}
    </Modal>
  );
};

export default MakeAPayment;

import { FC, Suspense, useEffect, useState, lazy } from 'react';
import { Modal, Form, Input } from 'antd';
import { mutationNotification, usePut } from '../../../hooks/useApi';
import { useFetchUser } from '../../../hooks/useFetchUser';

const PasswordStrengthBar = lazy(
  () => import('react-password-strength-bar') as Promise<any>
);

interface ChangePasswordProps {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
}

const ChangePassword: FC<ChangePasswordProps> = ({
  visible,
  onVisibleChange,
}) => {
  const user = useFetchUser({ enabled: false });
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [accountPassword, setAccountPassword] = useState('');

  const put = usePut<any, any>(`/v1/auth/me`);

  const dismiss = () => {
    onVisibleChange(false);
  };

  const OnOk = () => {
    put.mutate({
      password: newPassword,
      repeatPassword: repeatPassword,
      accountPassword: accountPassword,
    });
  };

  useEffect(() => {
    mutationNotification(put, {
      success: {
        title: 'Success',
        description: 'Update successful.',
      },
      error: {
        title: 'Update Failed',
      },
    });

    if (put.isSuccess) {
      const auth = JSON.parse(localStorage.getItem('auth') || '{}');
      localStorage.setItem(
        'auth',
        JSON.stringify({ ...auth, password: newPassword })
      );
      user.refetch();

      dismiss();
    }
  }, [put.isSuccess, put.isError]);

  return (
    <Modal
      title={'Change Password'}
      loading={put.isLoading}
      onOk={OnOk}
      okButtonProps={{
        disabled:
          !newPassword || !accountPassword || newPassword !== repeatPassword,
      }}
      okText={'Save/Update'}
      visible={visible}
      onCancel={dismiss}
    >
      <Form className={'pt-3'}>
        <Form.Item>
          <label htmlFor="currentPassword">Current Account Password</label>
          <Input
            id="currentPassword"
            type="password"
            value={accountPassword}
            onChange={(event) => setAccountPassword(event.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <label htmlFor="newPassword">New Password</label>
          <Input
            id="newPassword"
            type="password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <Suspense>
            <PasswordStrengthBar password={newPassword} />
          </Suspense>
        </Form.Item>
        <Form.Item>
          <label htmlFor="repeatPassword">Repeat Password</label>
          <Input
            id="repeatPassword"
            type="password"
            value={repeatPassword}
            onChange={(event) => setRepeatPassword(event.target.value)}
          />
          <Suspense>
            <PasswordStrengthBar password={repeatPassword} />
          </Suspense>
        </Form.Item>
      </Form>

      {newPassword && repeatPassword && newPassword !== repeatPassword && (
        <div className={'alert alert-danger'}>Passwords do not match</div>
      )}
    </Modal>
  );
};

export default ChangePassword;

import { Button, Card } from 'antd';
import CreditCardDiv from '../../../components/CreditCardDiv';
import React, { FC, useEffect, useState } from 'react';
import { mutationNotification, useDelete } from '../../../hooks/useApi';
import Swal from 'sweetalert2';
import PaymentProcessingDisclosureModal from '../modals/PaymentProcessingDisclosureModal';

type CurrentAutomaticPaymentProps = {
  data: any;
  onDeAuthorize: () => void;
};

const CurrentAutomaticPayment: FC<CurrentAutomaticPaymentProps> = ({
  data,
  onDeAuthorize,
}) => {
  const [
    paymentProcessingDisclosureModalvisible,
    setPaymentProcessingDisclosureModalvisible,
  ] = useState(false);

  const openPaymentProcessingDisclosureModal = (ev: any) => {
    ev.preventDefault();
    setPaymentProcessingDisclosureModalvisible(true);
  };

  const removeIntentDelete = useDelete<any>(
    '/v1/auth/dashboard/auto-pay/removeIntent/' + data.defaultPaymentMethod.id
  );

  useEffect(() => {
    mutationNotification(removeIntentDelete, {
      success: {
        title: 'Success',
        description: 'Payment de-authorized successfully',
      },
      error: {
        title: 'Error de-authorizing payment method',
      },
    });

    if (removeIntentDelete.isSuccess) {
      onDeAuthorize();
    }
  }, [removeIntentDelete.isSuccess, removeIntentDelete.isError]);

  const handleDeAuthorize = async (ev: any) => {
    ev.preventDefault();

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to de-autorize?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'red',
    });

    if (result.isConfirmed) {
      removeIntentDelete.mutate({});
    }
  };

  return (
    <>
      <Card
        id={'current-automatic-payment'}
        title={'Your Current Automatic Payment'}
      >
        <p>
          This is the card that you authorized for automatic payments. If you
          need to use a different card, you will need to first de-authorize this
          one.
        </p>

        <p>
          If you need to stop your automatic payments for any reason, you just
          need to de-authorize this card.
        </p>

        <hr />

        <div className={'pt-3'}>
          <CreditCardDiv
            creditCard={{
              holderName:
                data?.defaultPaymentMethod?.billing_details?.name ||
                data?.customer.name,
              number: `**** **** **** ${data?.defaultPaymentMethod.card.last4}`,
              expiryMonth: data?.defaultPaymentMethod.card.exp_month,
              expiryYear: data?.defaultPaymentMethod.card.exp_year,
              ccv: '',
              cpfCnpj: '',
              expiryDate: `${data?.defaultPaymentMethod.card.exp_month}/${data?.defaultPaymentMethod.card.exp_year}`,
              brand: data?.defaultPaymentMethod.card.brand,
            }}
          />
        </div>

        <div className={'text-center pt-3 pb-3'}>
          <Button
            style={{ width: '257px' }}
            type={'dashed'}
            onClick={handleDeAuthorize}
          >
            De-Authorize?
          </Button>
        </div>

        <hr />

        <p>
          {`If you haven't already, please take a moment to read our`}{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={openPaymentProcessingDisclosureModal}
            href={'#'}
            style={{ color: '#703895' }}
          >
            Payment Processing Disclosure
          </a>
          .
        </p>
      </Card>

      <PaymentProcessingDisclosureModal
        visible={paymentProcessingDisclosureModalvisible}
        onClose={() => setPaymentProcessingDisclosureModalvisible(false)}
      />
    </>
  );
};

export default CurrentAutomaticPayment;

import React, { FC, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import './LedgerTable.css';
import { LedgerLine } from '../../models/ledger-line.model';
import { floatToDollar } from '../../functions';
import moment from 'moment';
import { PrimaryGuardian } from '../../models/primary-guardian.model';
import { Student } from '../../models/student.model';

interface LedgerTableProps {
  primaryGuardian: PrimaryGuardian;
  students: Student[];
  rows: LedgerLine[];
  total: number;
  printTable?: number;
  onPrintTable?: () => void;
}

const LedgerTable: FC<LedgerTableProps> = ({
  primaryGuardian,
  students,
  rows,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  total,
  printTable,
  onPrintTable,
}) => {
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);

  const isSelected = (id: number) => selected.includes(id);

  const toggleCheckAll = () => {
    if (!checkAll) {
      // @ts-ignore
      setSelected([...Array(rows.length).keys()]);
    } else {
      setSelected([]);
    }

    setCheckAll(!checkAll);
  };

  const toggleChecked = (id: number) => {
    if (isSelected(id)) {
      setSelected(selected.filter((item) => item !== id));
      setCheckAll(false);
    } else {
      setSelected([...selected, id]);
    }
  };

  const POPUP_HTML = () => `
  <html>
     <head>
        <title>Print Ledger Table</title>
        <style>
           * {
           margin: 0;
           padding: 0;
           }
           .not-selected{
            display: none;
           }
           body {
           font-family: 'robotoregular';
           font-size: 11pt;
           }
           .paper {
           width: 100%;
           }
           .title {
           width: 50%;
           font-size: 24pt;
           text-align: center;
           font-weight: bold;
           float: left;
           margin-top: 34px;
           }
           .starInfo {
           width: 25%;
           font-size: 9pt;
           float: right;
           margin-top: 34px;
           }
           .row {
           width: 100%;
           display: inline-block;
           }
           .column {
           float: left;
           }
           .end {
           float: right;
           margin-right: 20px;
           }
           .center {
           text-align: center;
           }
           .right {
           text-align: right;
           }
           .primary, .child {
           width: 35%;
           font-size: 12pt;
           font-weight: bold;
           }
           .bold {
           font-weight: bold;
           }
           .underline {
           text-decoration: underline;
           }
           .tRowBBorder {
           border-bottom: 2px solid #000000;
           padding-bottom: 10px;
           }
           hr {
           border-bottom: 1px solid #000000;
           margin: 10px 0 10px 0;
           }
           tr {
           border-bottom: 1px solid #666666;
           }
           tr:nth-last-child(1) {
           border-bottom: none;
           }
           table {
           width: 100%;
           border-collapse: collapse;
           }
           th {
           text-align: left;
           }
           td {
           padding: 0 !important;
           margin: 0 !important;
           }
           .logo img {
           width: 150px;
           height: auto;
           }
           .logo {
           width: 25%;
           margin-left: auto;
           margin-right: auto;
           float: left;
           }
           hr {
           width: 98%;
           }
           .spacer {
           height: 5px;
           }
           .removeLine {
           background-color: #EEEEEE;
           color: #333333;
           font-weight: bold;
           }
           .removeBorder {
           border-bottom: 0;
           }
           .button {
           padding: 15px;
           background-color: #6D2390;
           color: #FFFFFF;
           }
           .button:hover {
           background-color: #3E1452;
           }
           a {
           text-decoration: none;
           }
           .ten {
           width: 10%;
           }
           .full {
           width: 100%;
           }
           .red {
           background-color: red;
           color: #222222;
           font-weight: bold;
           margin: 0 0 10px 0;
           }
           .coral {
           background-color: coral;
           color: #222222;
           font-weight: bold;
           margin: 10px 0 0 0;
           }
           p {
           padding: 10px;
           }
           .spacer {
           height: 25px;
           }
           @media print {
           .pageBreak {
           page-break-after: always;
           }
           .remove {
           display: none;
           }
           .removeLine {
           display: none;
           }
           table tr td {
           padding-left: 8px;
           padding-right: 8px;
           }
           table tr td:first-child {
           width: 12%;
           }
        </style>
     </head>
     <body>
        <div class="row">
           <div class="logo"><img src="https://main.starsacramento.org/static/media/starLogo.03ac529416083e6eae9b5f3ced231d38.svg"></div>
           <div class="title">
              STAR Family Ledger
              <br>
              <small>Child Care</small>
           </div>
           <div class="starInfo">
              STAR Inc.<br>
              4145 Delmar Ave.<br>
              Rocklin, CA 95667<br>
              Tax ID#: 95-4430228
           </div>
           <br clear="all">
           <div style="padding-left: 16px;padding-right: 16px">
            <table style="width: 100%">
                <tr>
                  <td style="width: 50%;vertical-align: middle">
                      <p style="padding-left: 0;margin-left: 0;padding-right: 0;margin-right: 0">Primary Guardian:</p>
                      <b>${primaryGuardian.firstName} ${primaryGuardian.lastName}</b>
                  </td>
                  <td style="width: 50%;text-align: right">
                      <p style="padding-left: 0;margin-left: 0;padding-right: 0;margin-right: 0">Students:</p>
                    ${students.map((student) => `<b>${student.firstName} ${student.lastName}</b>`).join('<br/>')}
                  </td>
                </tr>
            </table>
           </div>
        </div>
        <hr/>
        [[CONTENT]]
     </body>
  </html>
`;

  const handlePrint = () => {
    const printContent = document.getElementById('ledger-table')?.outerHTML;
    const printWindow = window.open('', '', 'height=500,width=800');

    if (printWindow) {
      printWindow.document.write(
        POPUP_HTML().replace('[[CONTENT]]', printContent || '')
      );
      printWindow.document.close();
      printWindow.print();
    }
  };

  const selectedsTotal = () => {
    let total = 0;

    for (const index of selected) {
      total += +rows[index].amount || 0;
    }

    return +total;
  };

  useEffect(() => {
    if (printTable === 1) {
      handlePrint();

      if (onPrintTable) {
        onPrintTable();
      }
    }
  }, [printTable]);

  return (
    <div className={'table-responsive'}>
      <Table className={'p-0 m-0'} striped={true}>
        <thead>
          <tr>
            <th>
              <input
                checked={checkAll}
                onChange={() => toggleCheckAll()}
                type={'checkbox'}
              />
            </th>
            <th>Date</th>
            <th>Student</th>
            <th>Transaction</th>
            <th>Memo</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 ? (
            <tr>
              <td className={'text-center'} colSpan={6}>
                No rows found
              </td>
            </tr>
          ) : (
            rows.map((row, index) => (
              <tr
                key={index}
                className={isSelected(index) ? 'selected' : 'not-selected'}
              >
                <td>
                  <input
                    checked={isSelected(index)}
                    onChange={() => toggleChecked(index)}
                    type={'checkbox'}
                  />
                </td>
                <td>{moment(row.date).format('MM/DD/YYYY')}</td>
                <td>
                  {row.Student?.id
                    ? `${row.Student?.firstName} ${row.Student?.lastName}`
                    : '-'}
                </td>
                <td>{row.transaction}</td>
                <td>{row.memo}</td>
                <td>${floatToDollar(+row.amount)}</td>
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr>
            <th className={'text-right'} colSpan={5}>
              Total
            </th>
            <th>${floatToDollar(selectedsTotal())}</th>
          </tr>
          {/*<tr className={'not-selected'}>*/}
          {/*  <th className={'text-right'} colSpan={5}>*/}
          {/*    Date Range Total*/}
          {/*  </th>*/}
          {/*  <th>${floatToDollar(+(total || 0))}</th>*/}
          {/*</tr>*/}
        </tfoot>
      </Table>

      <div style={{ display: 'none' }}>
        <Table id="ledger-table" className={'p-0 m-0'} striped={true}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Student</th>
              <th>Transaction</th>
              <th>Memo</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td className={'text-center'} colSpan={5}>
                  No rows found
                </td>
              </tr>
            ) : (
              rows.map((row, index) => (
                <tr
                  key={index}
                  className={isSelected(index) ? 'selected' : 'not-selected'}
                >
                  <td>{moment(row.date).format('MM/DD/YYYY')}</td>
                  <td>
                    {row.Student?.id
                      ? `${row.Student?.firstName} ${row.Student?.lastName}`
                      : '-'}
                  </td>
                  <td>{row.transaction}</td>
                  <td>{row.memo}</td>
                  <td>${floatToDollar(+row.amount)}</td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <th className={'text-right'} colSpan={4}>
                Total
              </th>
              <th>${floatToDollar(selectedsTotal())}</th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};

export default LedgerTable;

import { DollarOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

export default {
  programs: [
    {
      name: 'STAR Galaxy|ELO-P 2024-2025',
      href: 'https://reg.starsacramento.org',
    },
    {
      name: 'STAR Summer Camp 2024',
      href: 'https://reg.starsacramento.org',
    },
  ],
  menuItems: [
    {
      label: 'Account Summary',
      icon: <UserOutlined />,
      path: '/',
      primaryRequired: false,
    },
    {
      label: 'Primary Guardian',
      icon: <UserOutlined />,
      path: '/primary',
      primaryRequired: false,
    },
    {
      label: 'Additional Guardians',
      icon: <TeamOutlined />,
      path: '/additional-guardians',
      primaryRequired: true,
    },
    {
      label: 'Emergency Contacts',
      icon: <TeamOutlined />,
      path: '/emergency-contacts',
      primaryRequired: true,
    },
    {
      label: 'Students',
      icon: <UserOutlined />,
      path: '/students',
      primaryRequired: true,
    },
    {
      label: 'Ledger',
      icon: <DollarOutlined />,
      path: '/ledger',
      primaryRequired: true,
    },
    {
      label: 'AutoPay',
      icon: <DollarOutlined />,
      path: '/autoPay',
      primaryRequired: true,
    },
  ],
};

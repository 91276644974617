import React, { FC, useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import { Student } from '../../../models/student.model';
import { Col, Row, Table } from 'react-bootstrap';
import { mutationNotification, useGet, usePost } from '../../../hooks/useApi';
import { EmergencyContact } from '../../../models/emergency-contact.model';

interface StudentInformationProps {
  student: Partial<Student>;
}

const StudentEmergencyContacts: FC<StudentInformationProps> = ({ student }) => {
  const [newStudentEmergencyContacts, setNewStudentEmergencyContacts] =
    useState<number[]>([]);

  const emergencyContacts = useGet<EmergencyContact[]>(
    '/v1/auth/dashboard/emergency-contacts'
  );
  const studentEmergencyContacts = useGet<EmergencyContact[]>(
    '/v1/auth/dashboard/emergency-contacts/student/' + student.id
  );

  const post = usePost<any, any>(
    '/v1/auth/dashboard/emergency-contacts/student/' + student.id
  );

  useEffect(() => {
    if (studentEmergencyContacts.isSuccess) {
      setNewStudentEmergencyContacts(
        studentEmergencyContacts.data.map((x) => x.id)
      );
    }
  }, [studentEmergencyContacts.data]);

  useEffect(() => {
    mutationNotification(post, {
      success: {
        title: 'Success',
        description: 'Student information has been updated successfully.',
      },
      error: {
        title: 'Update Failed',
      },
    });
  }, [post.isSuccess, post.isError]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(e.target.value);
    setNewStudentEmergencyContacts((prevState) =>
      prevState.includes(id)
        ? prevState.filter((item) => item !== id)
        : [...prevState, id]
    );
  };

  const onFinish = (ev: any) => {
    ev.preventDefault();
    post.mutate(newStudentEmergencyContacts);
  };

  const Box = () => (
    <div
      style={{
        backgroundColor: '#eadef3',
        padding: '1rem',
        border: '1px solid rgba(10,10,10,.25)',
        fontSize: '16px',
      }}
    >
      <p className={'pb-3'}>
        Changes will only be saved if you click the save/update button after you
        have made your selections.
      </p>
      <p className={'pb-3'}>
        If there are no Emergency Contacts listed, you will need to add them
        before you can attach them to a student. That can be done{' '}
        <a
          href={'/emergency-contact/new'}
          target={'_blank'}
          style={{ color: '#703895' }}
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
  );

  return (
    <Row>
      <Col md={6}>
        <Card
          id={'Assign-Emergency-Contacts'}
          title="Assign Emergency Contacts"
          bordered={false}
          loading={
            emergencyContacts.isLoading ||
            studentEmergencyContacts.isLoading ||
            post.isLoading
          }
        >
          <p className={'pb-3'}>
            Check all Emergency Contacts that should be assigned to this
            student.
          </p>

          <Table bordered={true} striped={true}>
            <tbody>
              {emergencyContacts.data?.map((guardian) => (
                <tr key={guardian.id}>
                  <td style={{ width: '20px' }}>
                    <input
                      id={`emergencyContact-${guardian.id}`}
                      type="checkbox"
                      value={guardian.id}
                      checked={newStudentEmergencyContacts.includes(
                        guardian.id
                      )}
                      onChange={onChange}
                    />
                  </td>
                  <td>
                    {guardian.firstName} {guardian.lastName}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Button
            id="saveEmergencyContacts"
            block
            size="large"
            type="primary"
            htmlType="button"
            onClick={onFinish}
          >
            Save/Update
          </Button>
        </Card>
      </Col>
      <Col md={6}>
        <Box />
      </Col>
    </Row>
  );
};

export default StudentEmergencyContacts;

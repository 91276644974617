// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#google_translate_element .goog-te-combo {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
}

#google_translate_element img {
  float: left;
}
`, "",{"version":3,"sources":["webpack://./src/layout/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":["#google_translate_element .goog-te-combo {\n  width: 100%;\n  padding: 8px;\n  margin-bottom: 8px;\n}\n\n#google_translate_element img {\n  float: left;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// src/Context.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { User } from './models/user.model';

interface AppState {
  user: User | null;
  ledgerBalance: number;
}

interface ContextType {
  state: AppState;
  setState: Dispatch<SetStateAction<AppState>>;
}

const initialState: AppState = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : null,
  ledgerBalance: 0,
};

const Context = createContext<ContextType | undefined>(undefined);

export const useContextValue = (): ContextType => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useContextValue must be used within a Provider');
  }

  return context;
};

interface ProviderProps {
  children: ReactNode;
}

export const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [state, setState] = useState<AppState>(initialState);

  return (
    <Context.Provider value={{ state, setState }}>{children}</Context.Provider>
  );
};

import { FC } from 'react';
import { Button, Card, Skeleton, notification, Result, Popconfirm } from 'antd';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { getErrorMessage, useDelete, useGet } from '../../hooks/useApi';
import PageTitle from '../../layout/PageTitle/PageTitle';
import { Col, Row } from 'react-bootstrap';
import { AdditionalGuardian as AdditionalGuardianModel } from '../../models/additional-guardian.model';
import './List.css';
import { useFetchUser } from '../../hooks/useFetchUser';
import { useNavigate } from 'react-router-dom';
import { UserAddOutlined } from '@ant-design/icons';

const AdditionalGuardianList: FC = () => {
  const navigate = useNavigate();
  const user = useFetchUser();
  const { data, isLoading, isError, refetch } = useGet<
    AdditionalGuardianModel[]
  >('/v1/auth/dashboard/additional-guardians');

  const onNew = () => {
    navigate(`/additional-guardian/new`);
  };

  const onEdit = (id: number) => {
    navigate(`/additional-guardian/${id}`);
  };

  const OnEmpty = () => (
    <Result
      status="404"
      title="No additional guardians added yet"
      extra={
        <Button onClick={() => onNew()} type="primary">
          Add An Additional Guardian
        </Button>
      }
    />
  );

  const OnError = () => (
    <Result
      status="500"
      title="Error"
      subTitle="Error while loading the page"
      extra={
        <Button onClick={() => refetch()} type="primary">
          Try Again
        </Button>
      }
    />
  );

  const CardExtra: FC<{ id: number }> = ({ id }) => {
    const deleteMutation = useDelete(
      `/v1/auth/dashboard/additional-guardian/${id}`,
      {
        onSuccess: () => {
          refetch();
          notification.success({
            message: 'Success',
            description: 'Guardian deleted successfully',
          });
        },
        onError: (err) =>
          notification.error({
            message: 'Error',
            description: getErrorMessage(err.response),
          }),
      }
    );

    const OnDelete = () => {
      deleteMutation.mutate();
    };

    return (
      <>
        <Button type={'link'} onClick={() => onEdit(id)}>
          Edit
        </Button>

        <Popconfirm
          title="Delete"
          description="Are you sure to delete this?"
          onConfirm={() => OnDelete()}
          okText="Yes"
          cancelText="No"
        >
          <Button type={'link'} style={{ color: 'red' }}>
            Delete
          </Button>
        </Popconfirm>
      </>
    );
  };

  const Content = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (isError) {
      return OnError();
    }

    if ((data || []).length <= 0) {
      return OnEmpty();
    }

    return (
      <>
        <Row className="pt-4">
          {data?.map((guardian) => (
            <Col key={guardian.id} md={4} className={'pb-4'}>
              <Card
                className={'additional-guardian-card'}
                title={guardian.firstName + ' ' + guardian.lastName}
                extra={<CardExtra id={guardian.id} />}
              >
                <p>Cell Phone</p>
                <h5>{guardian.cellPhone}</h5>

                <p>Home Phone</p>
                <h5>{guardian.homePhone}</h5>

                <p>Email</p>
                <h5>{guardian.email}</h5>

                <p>DL Number</p>
                <h5>{guardian.driversLicense}</h5>
              </Card>
            </Col>
          ))}

          <Col md={4}>
            <Card>
              <Result
                icon={<UserAddOutlined />}
                title="Additional Guardian"
                style={{ padding: '72px' }}
                subTitle=""
                extra={[
                  <Button onClick={() => onNew()} type="primary" key="console">
                    Add New
                  </Button>,
                ]}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <SidebarPage>
      <PageTitle>
        {user.isLoading
          ? 'Loading...'
          : (user.data?.PrimaryGuardian?.lastName || '') +
            ' Family Account: Additional Guardians'}
      </PageTitle>

      <Content />
    </SidebarPage>
  );
};

export default AdditionalGuardianList;

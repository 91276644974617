import React, { FC, useEffect, useState } from 'react';
import { Card, Form, Input, Button, Alert, Checkbox } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Student } from '../../../models/student.model';
import { Col, Row } from 'react-bootstrap';
import { mutationNotification, useGet, usePost } from '../../../hooks/useApi';
import { MedicalInfo } from '../../../models/medical-info.model';

import './StudentMedicalInformation.css';

interface StudentInformationProps {
  student: Partial<Student>;
}

const StudentMedicalInformation: FC<StudentInformationProps> = ({
  student,
}) => {
  const [form] = useForm();
  const [formError, setFormError] = useState<string | null>(null);

  const medicalInfo = useGet<MedicalInfo>(
    '/v1/auth/dashboard/medical-info/student/' + student.id
  );

  const post = usePost<any, any>(
    '/v1/auth/dashboard/medical-info/student/' + student.id
  );

  useEffect(() => {
    if (medicalInfo.data && medicalInfo.data.id) {
      form.setFieldsValue({
        ...medicalInfo.data,
      });
    }
  }, [medicalInfo.data, form]);

  useEffect(() => {
    mutationNotification(post, {
      success: {
        title: 'Success',
        description: 'Student information has been updated successfully.',
      },
      error: {
        title: 'Update Failed',
      },
    });
  }, [post.isSuccess, post.isError]);

  const onFinish = (values: any) => {
    const medicalInfoNewData: Partial<MedicalInfo> = {
      ...values,
      seizures: values.seizures || false,
      fainting: values.fainting || false,
      diabetes: values.diabetes || false,
      migraines: values.migraines || false,
      heartCondition: values.heartCondition || false,
      asthma: values.asthma || false,
      allergies: values.allergies || false,
      beeSting: values.beeSting || false,
      hearingProblem: values.hearingProblem || false,
      tubes: values.tubes || false,
      hearingAid: values.hearingAid || false,
      lifeThreateningConditions: values.lifeThreateningConditions || false,
      add: values.add || false,
      autism: values.autism || false,
      aspergers: values.aspergers || false,
      processingDisorder: values.processingDisorder || false,
    };

    post.mutate(medicalInfoNewData);
    setFormError(null);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
    setFormError('Form submission failed');
  };

  const Box = () => (
    <div
      style={{
        backgroundColor: '#eadef3',
        padding: '1rem',
        border: '1px solid rgba(10,10,10,.25)',
        fontSize: '16px',
      }}
    >
      <p className={'pb-3'}>
        It is important to use this form to inform STAR of any health concerns
        for your student.
      </p>
      <p className={'pb-3'}>
        This information will show up on staff roll sheets and follow your
        student when they are in any STAR programs. It will not be shared with
        anyone else.
      </p>
    </div>
  );
  return (
    <Row>
      <Col md={6}>
        <Card
          id={'Medical-Information'}
          title="Medical Information"
          bordered={false}
          loading={post.isLoading || medicalInfo.isLoading}
        >
          <Form
            form={form}
            name="medicalInfo"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Pediatrician's First Name"
              name="pediatricianFirstName"
              rules={[
                {
                  required: true,
                  message: "Pediatrician's First Name is required",
                },
                {
                  pattern: /^[A-Za-z-]+$/,
                  message: "Pediatrician's First Name is invalid",
                },
              ]}
            >
              <Input
                id="pediatricianFirstName"
                placeholder="Pediatrician's First Name"
              />
            </Form.Item>
            <Form.Item
              label="Pediatrician's Last Name"
              name="pediatricianLastName"
              rules={[
                {
                  required: true,
                  message: "Pediatrician's Last Name is required",
                },
                {
                  pattern: /^[A-Za-z-]+$/,
                  message: "Pediatrician's Last Name is invalid",
                },
              ]}
            >
              <Input
                id="pediatricianLastName"
                placeholder="Pediatrician's Last Name"
              />
            </Form.Item>
            <Form.Item
              label="Pediatrician's Address"
              name="pediatricianAddress"
              rules={[
                {
                  required: true,
                  message: "Pediatrician's Address is required",
                },
              ]}
            >
              <Input
                id="pediatricianAddress"
                placeholder="Pediatrician's Address"
              />
            </Form.Item>
            <Form.Item
              label="Pediatrician's Phone"
              name="pediatricianPhone"
              rules={[
                { required: true, message: "Pediatrician's Phone is required" },
                {
                  pattern: /^[0-9]{3}[ -]?[0-9]{3}[ -]?[0-9]{4}$/,
                  message: 'Required Format: XXX-XXX-XXXX',
                },
              ]}
            >
              <Input
                id="pediatricianPhone"
                placeholder="Pediatrician's Phone"
              />
            </Form.Item>
            <Form.Item
              label="Insurance Carrier"
              name="insuranceCarrier"
              rules={[
                { required: true, message: 'Insurance Carrier is required' },
              ]}
            >
              <Input id="insuranceCarrier" placeholder="Insurance Carrier" />
            </Form.Item>
            <Form.Item
              label="Insurance Policy #"
              name="insurancePolicy"
              rules={[
                { required: true, message: 'Insurance Policy # is required' },
                {
                  pattern: /^[A-Za-z0-9]+$/,
                  message: 'Policy number is invalid',
                },
              ]}
            >
              <Input id="insurancePolicy" placeholder="Insurance Policy #" />
            </Form.Item>
            <Form.Item
              label="Hospital Preference"
              name="hospitalPreference"
              rules={[
                { required: true, message: 'Hospital Preference is required' },
              ]}
            >
              <Input
                id="hospitalPreference"
                placeholder="Hospital Preference"
              />
            </Form.Item>
            <Form.Item
              label="Preferred Hospital's Phone #"
              name="hospitalPhone"
              rules={[
                { required: true, message: "Hospital's Phone # is required" },
                {
                  pattern: /^[0-9]{3}[ -]?[0-9]{3}[ -]?[0-9]{4}$/,
                  message: 'Required Format: XXX-XXX-XXXX',
                },
              ]}
            >
              <Input
                id="hospitalPhone"
                placeholder="Preferred Hospital's Phone #"
              />
            </Form.Item>
            <Form.Item label="Does Your Child Have Any Of The Following Conditions (check all that apply):">
              <Form.Item name="seizures" valuePropName="checked" noStyle>
                <Checkbox id="seizures">Seizures</Checkbox>
              </Form.Item>
              <Form.Item name="fainting" valuePropName="checked" noStyle>
                <Checkbox id="fainting">Fainting</Checkbox>
              </Form.Item>
              <Form.Item name="diabetes" valuePropName="checked" noStyle>
                <Checkbox id="diabetes">Diabetes</Checkbox>
              </Form.Item>
              <Form.Item name="migraines" valuePropName="checked" noStyle>
                <Checkbox id="migraines">Migraines</Checkbox>
              </Form.Item>
              <Form.Item name="heartCondition" valuePropName="checked" noStyle>
                <Checkbox id="heartCondition">Heart Condition</Checkbox>
              </Form.Item>
              <Form.Item name="asthma" valuePropName="checked" noStyle>
                <Checkbox id="asthma">Asthma</Checkbox>
              </Form.Item>
              <Form.Item name="allergies" valuePropName="checked" noStyle>
                <Checkbox id="allergies">Allergies</Checkbox>
              </Form.Item>
              <Form.Item name="beeSting" valuePropName="checked" noStyle>
                <Checkbox id="beeSting">Bee Sting</Checkbox>
              </Form.Item>
              <Form.Item name="hearingProblem" valuePropName="checked" noStyle>
                <Checkbox id="hearingProblem">Hearing Problems</Checkbox>
              </Form.Item>
              <Form.Item name="tubes" valuePropName="checked" noStyle>
                <Checkbox id="tubes">Tubes In Ears</Checkbox>
              </Form.Item>
              <Form.Item name="hearingAid" valuePropName="checked" noStyle>
                <Checkbox id="hearingAid">Hearing Aid</Checkbox>
              </Form.Item>
              <Form.Item
                name="lifeThreateningConditions"
                valuePropName="checked"
                noStyle
              >
                <Checkbox id="lifeThreateningConditions">
                  Life Threatening Conditions
                </Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Please Describe Your Child's Allergic Reactions (including reactions to bee stings):"
              name="describeAllergies"
            >
              <Input.TextArea
                id="describeAllergies"
                rows={3}
                placeholder="Please Describe Your Child's Allergic Reactions"
              />
            </Form.Item>
            <Form.Item
              label="Please Describe Your Child's Life Threatening Conditions:"
              name="describeLifeThreateningConditions"
            >
              <Input.TextArea
                id="describeLifeThreateningConditions"
                rows={3}
                placeholder="Please Describe Your Child's Life Threatening Conditions"
              />
            </Form.Item>
            <Form.Item label="Does Your Child Have Any Of The Following Learning Disabilities (check all that apply):">
              <Form.Item name="add" valuePropName="checked" noStyle>
                <Checkbox id="add">ADD/ADHD</Checkbox>
              </Form.Item>
              <Form.Item name="autism" valuePropName="checked" noStyle>
                <Checkbox id="autism">Autism</Checkbox>
              </Form.Item>
              <Form.Item name="aspergers" valuePropName="checked" noStyle>
                <Checkbox id="aspergers">Aspergers</Checkbox>
              </Form.Item>
              <Form.Item
                name="processingDisorder"
                valuePropName="checked"
                noStyle
              >
                <Checkbox id="processingDisorder">Processing Disorder</Checkbox>
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="If Your Child Has A Learning Disability, Please Provide More Information Here:"
              name="learningDisabilities"
            >
              <Input.TextArea
                id="learningDisabilities"
                rows={3}
                placeholder="If Your Child Has A Learning Disability, Please Provide More Information Here"
              />
            </Form.Item>
            <Form.Item
              label="Please Describe Any Other Health Related Issues:"
              name="otherHealthRelatedIssues"
            >
              <Input.TextArea
                id="otherHealthRelatedIssues"
                rows={3}
                placeholder="Please Describe Any Other Health Related Issues"
              />
            </Form.Item>
            {formError && <Alert message={formError} type="error" showIcon />}
            <Button block size="large" type="primary" htmlType="submit">
              Save/Update
            </Button>
          </Form>
        </Card>
      </Col>
      <Col md={6}>
        <Box />
      </Col>
    </Row>
  );
};

export default StudentMedicalInformation;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ledger-table thead tr th {
  background-color: #f8f8f8;
}

#ledger-table tfoot tr th {
  background-color: #b3dff4;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Ledger/LedgerTable.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["#ledger-table thead tr th {\n  background-color: #f8f8f8;\n}\n\n#ledger-table tfoot tr th {\n  background-color: #b3dff4;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

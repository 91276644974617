import React, { FC, useEffect, useState } from 'react';
import { Card, Form, Input, Button, Alert } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { SharingInfo } from '../../../models/sharing-info.model';
import { Student } from '../../../models/student.model';
import { Col, Row } from 'react-bootstrap';
import { mutationNotification, useGet, usePost } from '../../../hooks/useApi';

import './StudentSharingInformation.css';

interface StudentSharingInformationProps {
  student: Partial<Student>;
}

const StudentSharingInformation: FC<StudentSharingInformationProps> = ({
  student,
}) => {
  const [form] = useForm();
  const [formError, setFormError] = useState<string | null>(null);

  const sharingInfo = useGet<SharingInfo>(
    '/v1/auth/dashboard/sharing-info/student/' + student.id
  );

  const post = usePost<any, any>(
    '/v1/auth/dashboard/sharing-info/student/' + student.id
  );

  useEffect(() => {
    if (sharingInfo.data && sharingInfo.data.id) {
      form.setFieldsValue({
        ...sharingInfo.data,
      });
    }
  }, [sharingInfo.data, form]);

  useEffect(() => {
    mutationNotification(post, {
      success: {
        title: 'Success',
        description: 'Sharing information has been updated successfully.',
      },
      error: {
        title: 'Update Failed',
      },
    });
  }, [post.isSuccess, post.isError]);

  const onFinish = (values: any) => {
    const sharingInfoNewData: Partial<SharingInfo> = {
      ...values,
    };

    post.mutate(sharingInfoNewData);
    setFormError(null);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
    setFormError('Form submission failed');
  };

  const Box = () => (
    <div
      style={{
        backgroundColor: '#eadef3',
        padding: '1rem',
        border: '1px solid rgba(10,10,10,.25)',
        fontSize: '16px',
      }}
    >
      <p>
        STAR will use this information to better meet the needs of your student.
      </p>
    </div>
  );

  return (
    <Row>
      <Col md={6}>
        <Card
          id={'Sharing-Information'}
          title="Sharing Information"
          bordered={false}
          loading={post.isLoading || sharingInfo.isLoading}
        >
          <Form
            form={form}
            name="sharingInfo"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Strengths My Child Has (including interest and hobbies):"
              name="strengths"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea
                id="strengths"
                rows={3}
                placeholder="Describe your child's strengths, interests, and hobbies here."
              />
            </Form.Item>
            <Form.Item
              label="Concerns For My Child While Attending School:"
              name="concernsSchool"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea
                id="concernsSchool"
                rows={3}
                placeholder="Describe any concerns you have for your child while attending school."
              />
            </Form.Item>
            <Form.Item
              label="Concerns For My Child While Attending A STAR Education Program:"
              name="concernsAfterSchool"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea
                id="concernsAfterSchool"
                rows={3}
                placeholder="Describe any concerns you have for your child while attending a STAR education program."
              />
            </Form.Item>
            <Form.Item
              label="Concerns For My Child While At Home:"
              name="concernsHome"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea
                id="concernsHome"
                rows={3}
                placeholder="Describe any concerns you have for your child while at home."
              />
            </Form.Item>
            <Form.Item
              label="Concerns For My Child While Interacting With Peers:"
              name="concernsPeers"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea
                id="concernsPeers"
                rows={3}
                placeholder="Describe any concerns you have for your child while interacting with peers."
              />
            </Form.Item>
            <Form.Item
              label="Techniques We Can Use To Help Your Child Be More Successful:"
              name="techniques"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea
                id="techniques"
                rows={3}
                placeholder="Describe techniques that can help your child be more successful."
              />
            </Form.Item>
            <Form.Item
              label="Things That Motivate Your Child:"
              name="motivate"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Input.TextArea
                id="motivate"
                rows={3}
                placeholder="Describe things that motivate your child."
              />
            </Form.Item>
            {formError && <Alert message={formError} type="error" showIcon />}
            <Button block size="large" type="primary" htmlType="submit">
              Save/Update
            </Button>
          </Form>
        </Card>
      </Col>
      <Col md={6}>
        <Box />
      </Col>
    </Row>
  );
};

export default StudentSharingInformation;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-empty-image img {
  margin-left: auto;
  margin-right: auto;
}

.emergency-contact-card h5 {
  font-family: Oswald;
  color: #703895;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.5625rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EmergencyContact/List.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".ant-empty-image img {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.emergency-contact-card h5 {\n  font-family: Oswald;\n  color: #703895;\n  margin-top: 0;\n  margin-bottom: 16px;\n  font-size: 1.5625rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

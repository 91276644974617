// src/hooks/useLoginMutation.ts
import { usePost } from './useApi';
import { useContextValue } from '../Context';
import { User } from '../models/user.model';

interface LoginData {
  username: string;
  password: string;
}

export const useLoginMutation = () => {
  const { setState } = useContextValue();

  return usePost<User, LoginData>('/v1/auth/me', {
    onSuccess: (data) => {
      localStorage.setItem('user', JSON.stringify(data));
      setState((prevState) => ({ ...prevState, user: data }));
    },
  });
};

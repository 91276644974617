import React, { FC, ReactNode } from 'react';
import Header from '../Header/Header';
import Content from '../Content/Content';
import Footer from '../Footer/Footer';
import Page from '../Page/Page';

interface PageProps {
  children: ReactNode;
}

const SimplePage: FC<PageProps> = ({ children }) => {
  return (
    <Page>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </Page>
  );
};

export default SimplePage;

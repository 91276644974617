import React, { FC, useEffect, useState } from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Alert,
  DatePicker,
  Select,
  Radio,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { Student } from '../../../models/student.model';
import { Col, Row } from 'react-bootstrap';
import {
  mutationNotification,
  useGet,
  usePost,
  usePut,
} from '../../../hooks/useApi';
import { Grade } from '../../../models/grade.model';
import { ShirtSize } from '../../../models/shirt-size.model';
import { School } from '../../../models/school.model';

const { Option } = Select;

interface StudentInformationProps {
  student: Partial<Student>;
  updated?: (student: Student) => any;
  created?: (student: Student) => any;
}

const StudentInformation: FC<StudentInformationProps> = ({
  student,
  updated,
  created,
}) => {
  const [form] = useForm();
  const [formError, setFormError] = useState<string | null>(null);
  const grades = useGet<Grade[]>('/v1/auth/dashboard/grades');
  const shirtSizes = useGet<ShirtSize[]>('/v1/auth/dashboard/shirt-sizes');
  const schools = useGet<School[]>('/v1/auth/dashboard/schools');

  const put = usePut<any, any>('/v1/auth/dashboard/student/' + student.id);
  const post = usePost<any, any>('/v1/auth/dashboard/student/');

  useEffect(() => {
    let residence = '';

    if (student.pgReside) {
      residence = 'pgReside';
    }

    if (student.agReside) {
      residence = 'agReside';
    }

    if (student.resideBoth) {
      residence = 'resideBoth';
    }

    form.setFieldsValue({
      ...student,
      birthdate: student.birthdate ? moment(student.birthdate) : null,
      canBePhotographed: student.canBePhotographed ? 'true' : 'false',
      custody: student.custody ? 'true' : 'false',
      residence,
    });
  }, [student, form]);

  useEffect(() => {
    if (put.isSuccess && updated) {
      updated(put.data);
    }

    mutationNotification(put, {
      success: {
        title: 'Success',
        description: 'Student information has been updated successfully.',
      },
      error: {
        title: 'Update Failed',
      },
    });
  }, [put.isSuccess, put.isError]);

  useEffect(() => {
    if (post.isSuccess && created) {
      created(post.data);
    }

    mutationNotification(post, {
      success: {
        title: 'Success',
        description: 'Student information has been created successfully.',
      },
      error: {
        title: 'Update Failed',
      },
    });
  }, [post.isSuccess, post.isError]);

  const onFinish = (values: any) => {
    const studentNewData = JSON.parse(JSON.stringify(values));

    studentNewData.birthdate = moment(studentNewData.birthdate).format(
      'YYYY-MM-DD'
    );
    studentNewData.pgReside = false;
    studentNewData.agReside = false;
    studentNewData.resideBoth = false;

    if (values.residence === 'pgReside') {
      studentNewData.pgReside = true;
    }

    if (values.residence === 'agReside') {
      studentNewData.agReside = true;
    }

    if (values.residence === 'resideBoth') {
      studentNewData.resideBoth = true;
    }

    (student.id ? put : post).mutate(studentNewData);
    setFormError(null);
  };

  const onFinishFailed = (errorInfo: any) => {
    // Handle form submission failure
    console.log(errorInfo);
    setFormError('Form submission failed');
  };

  const Box = () => (
    <div
      style={{
        backgroundColor: '#eadef3',
        padding: '1rem',
        border: '1px solid rgba(10,10,10,.25)',
        fontSize: '16px',
      }}
    >
      <p className={'pb-3'}>Your student is currently enrollable!</p>
      <p className={'pb-3'}>
        Please visit{' '}
        <a
          href={'https://starsacramento.org/'}
          target={'_blank'}
          style={{ color: '#703895' }}
          rel="noreferrer"
        >
          starsacramento.org
        </a>{' '}
        to see what programs are currently enrolling.
      </p>
      <p className={'pb-3'}>
        Any changes you make and save here will automatically be reflected in
        the system. If your student is currently enrolled in a program, the
        program director will be notified of the change.
      </p>
    </div>
  );
  return (
    <Row>
      <Col md={6}>
        <Card
          id={'Student-Information'}
          title="Student Information"
          bordered={false}
          loading={put.isLoading || post.isLoading}
        >
          <Form
            form={form}
            name="student"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              ...student,
              birthdate: student.birthdate ? moment(student.birthdate) : null,
              canBePhotographed: student.canBePhotographed ? 'true' : 'false',
              custody: student.custody ? 'true' : 'false',
              residence: student.residence,
            }}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: 'First Name is required' },
                { pattern: /^[A-Za-z-]+$/, message: 'First Name is invalid' },
              ]}
            >
              <Input id="firstName" placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: 'Last Name is required' },
                { pattern: /^[A-Za-z-]+$/, message: 'Last Name is invalid' },
              ]}
            >
              <Input id="lastName" placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              label="Relation"
              name="relation"
              rules={[
                { required: true, message: 'Relation is required' },
                { pattern: /^[A-Za-z-]+$/, message: 'Relation is invalid' },
              ]}
            >
              <Input id="relation" placeholder="Relation" />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <Input id="gender" placeholder="Gender" />
            </Form.Item>
            <Form.Item
              label="Race/Ethnicity (information used for reporting purposes only) optional"
              name="race"
            >
              <Input id="race" placeholder="Race" />
            </Form.Item>
            <Form.Item
              label="Student Language"
              name="studentLanguage"
              rules={[
                { required: true, message: 'Student Language is required' },
                {
                  pattern: /^[A-Za-z-]+$/,
                  message: 'Student Language is invalid',
                },
              ]}
            >
              <Input id="studentLanguage" placeholder="Student Language" />
            </Form.Item>
            <Form.Item
              label="Home Language"
              name="homeLanguage"
              rules={[
                { required: true, message: 'Home Language is required' },
                {
                  pattern: /^[A-Za-z-]+$/,
                  message: 'Home Language is invalid',
                },
              ]}
            >
              <Input id="homeLanguage" placeholder="Home Language" />
            </Form.Item>
            <Form.Item
              label="Birthdate"
              name="birthdate"
              rules={[{ required: true, message: 'Birthdate is required' }]}
            >
              <DatePicker
                id="birthdate"
                placeholder="yyyy-mm-dd"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              label="Primary Residence"
              name="residence"
              rules={[{ required: true, message: 'Residence is required' }]}
            >
              <Radio.Group id="residence">
                <Radio id="pgReside" value="pgReside">
                  Primary Guardian
                </Radio>
                <Radio id="agReside" value="agReside">
                  Additional Guardian
                </Radio>
                <Radio id="resideBoth" value="resideBoth">
                  Both/All
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Court Ordered Custody Arrangement"
              name="custody"
              rules={[
                { required: true, message: 'Custody Arrangement is required' },
              ]}
            >
              <Radio.Group id="custody">
                <Radio id="custodyYes" value="true">
                  Yes
                </Radio>
                <Radio id="custodyNo" value="false">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Select School" name="schoolId">
              <Select
                id="schoolId"
                placeholder="Select School"
                loading={schools.isLoading}
              >
                {schools.data?.map((school) => (
                  <Option key={school.id} value={school.id}>
                    {school.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Select Grade"
              name="gradeId"
              rules={[
                { required: true, message: 'Grade selection is required' },
              ]}
            >
              <Select
                id="gradeId"
                placeholder="Select Grade"
                loading={grades.isLoading}
              >
                {grades.data?.map((grade) => (
                  <Option key={grade.id} value={grade.id}>
                    {grade.grade}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Select T-shirt Size (Used for Preschool/Nova programs only)"
              name="shirtSizeId"
            >
              <Select
                id="shirtSizeId"
                placeholder="Select T-shirt Size"
                loading={shirtSizes.isLoading}
              >
                {shirtSizes.data?.map((shirtSize) => (
                  <Option key={shirtSize.id} value={shirtSize.id}>
                    {shirtSize.size}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Can This Student Be Photographed?"
              name="canBePhotographed"
              rules={[
                {
                  required: true,
                  message: 'Photography agreement is required',
                },
              ]}
            >
              <Radio.Group id="canBePhotographed">
                <Radio id="canBePhotographedYes" value="true">
                  Yes
                </Radio>
                <Radio id="canBePhotographedNo" value="false">
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
            {formError && <Alert message={formError} type="error" showIcon />}
            <Button block size="large" type="primary" htmlType="submit">
              Save/Update
            </Button>
          </Form>
        </Card>
      </Col>
      <Col md={6}>
        <Box />
      </Col>
    </Row>
  );
};

export default StudentInformation;

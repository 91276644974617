import React, { FC, useEffect, useState } from 'react';
import { message, Modal, Skeleton } from 'antd';
import CreditCardDiv from '../../../components/CreditCardDiv';
import { useStripe } from '@stripe/react-stripe-js';
import { usePut } from '../../../hooks/useApi';

const ConfirmPaymentSetupModal: FC<{
  visible: boolean;
  onCancel: () => void;
  onConfirmPaymentSetup: () => void;
  selectedPaymentMethod: any;
  customerName: string;
}> = ({
  visible,
  onCancel,
  onConfirmPaymentSetup,
  selectedPaymentMethod,
  customerName,
}) => {
  const stripe = useStripe();
  const [stripeLoading, setStripeLoading] = useState(false);

  const setupIntentPut = usePut<any, any>(
    '/v1/auth/dashboard/auto-pay/setupIntent/' + selectedPaymentMethod.id
  );

  useEffect(() => {
    if (setupIntentPut.isSuccess) {
      confirmPaymentSetup();
    }

    if (setupIntentPut.isError) {
      message.error('Error updating payment method');
    }
  }, [setupIntentPut.isSuccess, setupIntentPut.isError]);

  const sendPaymentSetup = async () => {
    setupIntentPut.mutate({});
  };

  const confirmIntentPut = usePut<any, any>(
    '/v1/auth/dashboard/auto-pay/confirmIntent/' + selectedPaymentMethod.id
  );

  useEffect(() => {
    if (confirmIntentPut.isSuccess) {
      onConfirmPaymentSetup();
      message.success('Setup intent confirmed');
      setStripeLoading(false);
    }

    if (confirmIntentPut.isError) {
      message.error('Error updating payment method');
      setStripeLoading(false);
    }
  }, [confirmIntentPut.isSuccess, confirmIntentPut.isError]);

  const confirmPaymentSetup = async () => {
    if (!selectedPaymentMethod) {
      message.error('No payment method selected');
      return;
    }

    if (!stripe) {
      message.error('Stripe has not loaded yet');
      return;
    }

    const clientSecret = setupIntentPut.data.client_secret;

    if (!clientSecret) {
      message.error('ClientSecret has not loaded yet');
      return;
    }

    setStripeLoading(true);

    try {
      const { setupIntent, error } = await stripe.confirmSetup({
        clientSecret,
        redirect: 'if_required',
        return_url: window.location.href,
      } as any);

      if (error) {
        message.error(error.message || 'Error confirming setup intent');
        return;
      }

      confirmIntentPut.mutate(setupIntent);
    } catch (error) {
      console.error(error);
      message.error('Error confirming payment setup');
    } finally {
      setStripeLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={sendPaymentSetup}
      title="Use Card For Future Payments"
      okText={'Confirm'}
      cancelButtonProps={{
        disabled: setupIntentPut.isLoading || stripeLoading,
      }}
      okButtonProps={{ loading: setupIntentPut.isLoading || stripeLoading }}
    >
      {setupIntentPut.isLoading || stripeLoading ? (
        <Skeleton active />
      ) : (
        <div id={'confirmPaymentSetupModal'}>
          <div className={'mt-3 mb-4'}>
            {selectedPaymentMethod && (
              <CreditCardDiv
                creditCard={{
                  holderName:
                    selectedPaymentMethod.billing_details?.name || customerName,
                  number: `**** **** **** ${selectedPaymentMethod.card.last4}`,
                  expiryMonth: selectedPaymentMethod.card.exp_month,
                  expiryYear: selectedPaymentMethod.card.exp_year,
                  ccv: '',
                  cpfCnpj: '',
                  expiryDate: `${selectedPaymentMethod.card.exp_month}/${selectedPaymentMethod.card.exp_year}`,
                  brand: selectedPaymentMethod.card.brand,
                }}
              />
            )}
          </div>

          <div className={'pt-2'}>
            <p>
              By selecting this card and confirming it, you are agreeing to pay
              your tuition on a recurring basis.
            </p>
            <p>
              The payment schedule is determined by your student enrollment and
              will likely occur no less than once a month.
            </p>
            <p>
              The payment amount will never exceed the current balance on the
              account, ensuring that any credits are taken into account before
              the payment is made.
            </p>
            <p>
              In the event that your bank refuses payment for any reason, STAR
              will attempt to notify you, but it is your responsibility to make
              your payment on time.
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmPaymentSetupModal;

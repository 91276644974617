import { FC, useState, useEffect } from 'react';
import { Form, Input, Button, Card, Alert } from 'antd';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { mutationNotification, usePost } from '../../hooks/useApi';
import PageTitle from '../../layout/PageTitle/PageTitle';
import { Col, Row } from 'react-bootstrap';
import { useFetchUser } from '../../hooks/useFetchUser';
import { useNavigate } from 'react-router-dom';

const AdditionalGuardianNew: FC = () => {
  const navigate = useNavigate();
  const user = useFetchUser();

  const mutation = usePost<any, any>('/v1/auth/dashboard/additional-guardian/');

  const [form] = Form.useForm();
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(
    () =>
      mutationNotification(mutation, {
        success: {
          title: 'Success',
          description:
            'The additional guardian information has been recorded successfully.',
        },
        error: {
          title: 'Update Failed',
        },
      }),
    [mutation.isSuccess, mutation.isError]
  );

  useEffect(() => {
    if (mutation.isSuccess) {
      navigate('/additional-guardians');
    }
  }, [mutation.isSuccess]);

  const onFinish = (values: Partial<any>) => {
    mutation.mutate(values);
    setFormError(null);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setFormError(
      'Please double-check the entire form for missing required information and then try saving the information again.'
    );
  };

  const Box = () => (
    <div>
      <div
        style={{
          backgroundColor: '#eadef3',
          padding: '1rem',
          border: '1px solid rgba(10,10,10,.25)',
          fontSize: '16px',
        }}
      >
        <p className={'pb-3'}>
          Please fill out all fields to create a new Additional Guardian.
        </p>
        <p>
          Additional guardians are expected to be actual legal guardians. You do
          not need to re-enter the primary guardian here.
        </p>
      </div>

      <div
        style={{
          marginTop: '16px',
          padding: '1rem',
          border: '3px solid red',
          fontSize: '16px',
        }}
      >
        <p>
          <b>
            ONCE YOU HAVE SAVED YOUR ADDITIONAL GUARDIAN INFORMATION, YOU MUST
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            ASSIGN THEM TO YOUR STUDENT'S PROFILE UNDER THE "STUDENT" TAB.
          </b>
        </p>
      </div>
    </div>
  );

  const PrimaryGuardianTab = () => (
    <Card
      loading={mutation.isLoading}
      title="Additional Guardian"
      bordered={false}
      style={{
        borderRadius: '0',
        boxShadow: '0',
        paddingBottom: '0',
        border: '0',
      }}
      bodyStyle={{
        borderRadius: '0',
        boxShadow: '0',
        paddingBottom: '0',
        border: '0',
      }}
    >
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: 'First Name is required' }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: 'Last Name is required' }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        label="Relationship To Student"
        name="relation"
        rules={[{ required: true, message: 'Relationship To Student' }]}
      >
        <Input placeholder="Relationship To Student" />
      </Form.Item>
      <Form.Item
        label="Driver's License"
        name="driversLicense"
        rules={[{ required: true, message: "Driver's License is required" }]}
      >
        <Input placeholder="Driver's License" />
      </Form.Item>
      <Form.Item
        label="Cell Phone"
        name="cellPhone"
        rules={[
          {
            required: true,
            pattern: /^\d{3}[- ]\d{3}[- ]\d{4}$/,
            message: 'Required Format: XXX-XXX-XXXX',
          },
        ]}
      >
        <Input placeholder="Cell Phone" />
      </Form.Item>
      <Form.Item
        label="Home Phone"
        name="homePhone"
        rules={[
          {
            required: true,
            pattern: /^\d{3}[- ]\d{3}[- ]\d{4}$/,
            message: 'Required Format: XXX-XXX-XXXX',
          },
        ]}
      >
        <Input placeholder="Home Phone" />
      </Form.Item>
    </Card>
  );

  const ResidenceTab = () => (
    <Card
      loading={mutation.isLoading}
      title="Residence"
      bordered={false}
      style={{
        borderRadius: '0',
        boxShadow: '0',
        paddingBottom: '0',
        border: '0',
      }}
      bodyStyle={{
        borderRadius: '0',
        boxShadow: '0',
        paddingBottom: '0',
        border: '0',
      }}
    >
      <Form.Item
        label="Home Address"
        name="address"
        rules={[{ required: true, message: 'Home Address is required' }]}
      >
        <Input placeholder="Home Address" />
      </Form.Item>
      <Form.Item
        label="City"
        name="city"
        rules={[{ required: true, message: 'City is required' }]}
      >
        <Input placeholder="City" />
      </Form.Item>
      <Form.Item
        label="Zip Code"
        name="zip"
        rules={[{ required: true, message: 'Zip Code is required' }]}
      >
        <Input placeholder="Zip Code" />
      </Form.Item>
    </Card>
  );

  const EmployerTab = () => (
    <Card
      loading={mutation.isLoading}
      title="Employer"
      bordered={false}
      style={{
        borderRadius: '0',
        boxShadow: '0',
        paddingBottom: '0',
        border: '0',
      }}
      bodyStyle={{
        borderRadius: '0',
        boxShadow: '0',
        paddingBottom: '0',
        border: '0',
      }}
    >
      <Form.Item
        label="Employer"
        name="employer"
        rules={[{ required: true, message: 'Employer is required' }]}
      >
        <Input placeholder="Employer" />
      </Form.Item>
      <Form.Item
        label="Position"
        name="position"
        rules={[{ required: true, message: 'Position is required' }]}
      >
        <Input placeholder="Position" />
      </Form.Item>
      <Form.Item
        label="Employer's Phone"
        name="employerPhone"
        rules={[
          {
            required: true,
            pattern: /^\d{3}[- ]\d{3}[- ]\d{4}$/,
            message: 'Required Format: XXX-XXX-XXXX',
          },
        ]}
      >
        <Input placeholder="Employer's Phone" />
      </Form.Item>
      <Form.Item
        label="Employer's City"
        name="employerCity"
        rules={[{ required: true, message: "Employer's City is required" }]}
      >
        <Input placeholder="Employer's City" />
      </Form.Item>
    </Card>
  );

  return (
    <SidebarPage>
      <PageTitle>
        {user.isLoading
          ? 'Loading...'
          : (user.data?.PrimaryGuardian?.lastName || '') +
            ' Family Account: Additional Guardians'}
      </PageTitle>

      <Row>
        <Col md={6}>
          <Card bodyStyle={{ padding: '0' }}>
            <Form
              form={form}
              name="employer"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <PrimaryGuardianTab />
              <ResidenceTab />
              <EmployerTab />

              <div style={{ padding: '24px' }}>
                {formError && (
                  <Alert
                    className={'mb-3'}
                    message={formError}
                    type="error"
                    showIcon
                  />
                )}

                <Button block size={'large'} type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <Col md={6}>
          <Box />
        </Col>
      </Row>
    </SidebarPage>
  );
};

export default AdditionalGuardianNew;

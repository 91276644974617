// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#confirmPaymentSetupModal {
  padding-top: 12px;
}
#confirmPaymentSetupModal p {
  padding-bottom: 12px;
  font-size: 16px;
  text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AutoPay/components/ChooseCreditCard.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":["#confirmPaymentSetupModal {\n  padding-top: 12px;\n}\n#confirmPaymentSetupModal p {\n  padding-bottom: 12px;\n  font-size: 16px;\n  text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { FC } from 'react';
import { floatToDollar } from '../../../functions';
import { useFetchLedgerBalance } from '../../../hooks/useFetchLedgerBalance';

const LedgerCurrentBalance: FC = () => {
  const { data, isLoading, isError, refetch } = useFetchLedgerBalance();

  const tryAgain = (ev: React.MouseEvent) => {
    ev.preventDefault();
    refetch();
  };

  if (isError) {
    return (
      <>
        Error loading balance.{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={'#'} onClick={tryAgain}>
          Try Again
        </a>
      </>
    );
  }

  if (isLoading) {
    return <>Loading...</>;
  }

  return `$${floatToDollar(data ? +data : 0)}`;
};

export default LedgerCurrentBalance;

import { FC } from 'react';
import { Button, Card, Skeleton, notification, Result, Popconfirm } from 'antd';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { getErrorMessage, useDelete, useGet } from '../../hooks/useApi';
import PageTitle from '../../layout/PageTitle/PageTitle';
import { Col, Row } from 'react-bootstrap';
import { EmergencyContact as EmergencyContactModel } from '../../models/emergency-contact.model';
import './List.css';
import { useFetchUser } from '../../hooks/useFetchUser';
import { useNavigate } from 'react-router-dom';
import { UserAddOutlined } from '@ant-design/icons';

const EmergencyContactList: FC = () => {
  const navigate = useNavigate();
  const user = useFetchUser();
  const { data, isLoading, isError, refetch } = useGet<EmergencyContactModel[]>(
    '/v1/auth/dashboard/emergency-contacts'
  );

  const onNew = () => {
    navigate(`/emergency-contact/new`);
  };

  const onEdit = (id: number) => {
    navigate(`/emergency-contact/${id}`);
  };

  const onEmpty = () => (
    <Result
      status="404"
      title="No emergency contacts added yet"
      extra={
        <Button onClick={() => onNew()} type="primary">
          Add An Emergency Contact
        </Button>
      }
    />
  );

  const onError = () => (
    <Result
      status="500"
      title="Error"
      subTitle="Error while loading the page"
      extra={
        <Button onClick={() => refetch()} type="primary">
          Try Again
        </Button>
      }
    />
  );

  const CardExtra: FC<{ id: number }> = ({ id }) => {
    const deleteMutation = useDelete(
      `/v1/auth/dashboard/emergency-contact/${id}`,
      {
        onSuccess: () => {
          refetch();
          notification.success({
            message: 'Success',
            description: 'Contact deleted successfully',
          });
        },
        onError: (err) =>
          notification.error({
            message: 'Error',
            description: getErrorMessage(err.response),
          }),
      }
    );

    const OnDelete = () => {
      deleteMutation.mutate();
    };

    return (
      <>
        <Button type={'link'} onClick={() => onEdit(id)}>
          Edit
        </Button>

        <Popconfirm
          title="Delete"
          description="Are you sure to delete this?"
          onConfirm={() => OnDelete()}
          okText="Yes"
          cancelText="No"
        >
          <Button type={'link'} style={{ color: 'red' }}>
            Delete
          </Button>
        </Popconfirm>
      </>
    );
  };

  const Content = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (isError) {
      return onError();
    }

    if ((data || []).length <= 0) {
      return onEmpty();
    }

    return (
      <>
        <Row className="pt-4">
          {data?.map((contact) => (
            <Col key={contact.id} md={4} className={'pb-4'}>
              <Card
                className={'emergency-contact-card'}
                title={contact.firstName + ' ' + contact.lastName}
                extra={<CardExtra id={contact.id} />}
              >
                <p>Phone</p>
                <h5>{contact.phone}</h5>

                <p>Can Pick Up?</p>
                <h5>{contact.canPickup ? 'Yes' : 'No'}</h5>

                <p>DL Number</p>
                <h5>{contact.driversLicense}</h5>
              </Card>
            </Col>
          ))}

          <Col md={4}>
            <Card>
              <Result
                icon={<UserAddOutlined />}
                title="Emergency Contact"
                style={{ padding: '36px' }}
                subTitle=""
                extra={[
                  <Button onClick={() => onNew()} type="primary" key="console">
                    Add New
                  </Button>,
                ]}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <SidebarPage>
      <PageTitle>
        {user.isLoading
          ? 'Loading...'
          : (user.data?.PrimaryGuardian?.lastName || '') +
            ' Family Account: Emergency Contacts'}
      </PageTitle>

      <Content />
    </SidebarPage>
  );
};

export default EmergencyContactList;

import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CreditCardItem from './CreditCardItem';
import { Button, Card, Result } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import AddCardModal from '../modals/AddCardModal';
import UpdateCardModal from '../modals/UpdateCardModal';
import ConfirmPaymentSetupModal from '../modals/ConfirmPaymentSetupModal';

const CreditCardList: FC<{
  creditCards: any[];
  customer: any;
  creditCardAdded: () => void;
  creditCardUpdated: () => void;
  creditCardDeleted: () => void;
}> = ({
  creditCards,
  customer,
  creditCardAdded,
  creditCardUpdated,
  creditCardDeleted,
}) => {
  const [customerName, setCustomerName] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [addCardModalVisible, setAddCardModalVisible] = useState(false);
  const [confirmPaymentSetupVisible, setConfirmPaymentSetupVisible] =
    useState(false);

  const handleAddCard = () => {
    setAddCardModalVisible(true);
  };

  const handleSelectCard = (paymentMethod: any) => {
    setSelectedPaymentMethod(paymentMethod);
    setConfirmPaymentSetupVisible(true);
  };

  const handleUpdateCard = (paymentMethod: any) => {
    setSelectedPaymentMethod(paymentMethod);
    setUpdateModalVisible(true);
  };

  useEffect(() => {
    setCustomerName(customer.name);
  }, [customer]);

  return (
    <>
      <Row>
        {creditCards.map((paymentMethod, index) => (
          <Col md={4} className={'mb-3'}>
            <CreditCardItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              index={index}
              customerName={customerName}
              handleUpdateCard={handleUpdateCard}
              handleSelectCard={handleSelectCard}
              creditCardDeleted={creditCardDeleted}
            />
          </Col>
        ))}

        <Col md={4}>
          <Card>
            <Result
              icon={<CreditCardOutlined />}
              title="Credit Card"
              style={{ padding: '42px' }}
              subTitle=""
              extra={[
                <Button onClick={handleAddCard} type="primary" key="console">
                  Add New
                </Button>,
              ]}
            />
          </Card>
        </Col>
      </Row>

      {/* Modals */}

      <AddCardModal
        customerName={customerName}
        visible={addCardModalVisible}
        onCancel={() => setAddCardModalVisible(false)}
        onAddCard={() => {
          setAddCardModalVisible(false);
          creditCardAdded();
        }}
      />

      {selectedPaymentMethod && (
        <>
          <UpdateCardModal
            visible={updateModalVisible}
            customerName={customerName}
            onCancel={() => setUpdateModalVisible(false)}
            selectedPaymentMethod={selectedPaymentMethod}
            onUpdateCardExpiry={() => {
              setUpdateModalVisible(false);
              creditCardUpdated();
            }}
          />

          <ConfirmPaymentSetupModal
            visible={confirmPaymentSetupVisible}
            onCancel={() => setConfirmPaymentSetupVisible(false)}
            onConfirmPaymentSetup={() => {
              setConfirmPaymentSetupVisible(false);
              creditCardUpdated();
            }}
            selectedPaymentMethod={selectedPaymentMethod}
            customerName={customerName}
          />
        </>
      )}
    </>
  );
};

export default CreditCardList;

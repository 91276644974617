import { FC, useEffect, useState } from 'react';
import { Modal, Form, Input } from 'antd';
import { mutationNotification, usePut } from '../../../hooks/useApi';
import { useFetchUser } from '../../../hooks/useFetchUser';

interface ChangeEmailProps {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
}

const ChangeEmail: FC<ChangeEmailProps> = ({ visible, onVisibleChange }) => {
  const user = useFetchUser({ enabled: false });
  const [newEmail, setNewEmail] = useState('');
  const [accountPassword, setAccountPassword] = useState('');

  const put = usePut<any, any>('/v1/auth/me');

  const dismiss = () => {
    onVisibleChange(false);
  };

  const onOk = () => {
    put.mutate({
      email: newEmail,
      accountPassword: accountPassword,
    });
  };

  useEffect(() => {
    mutationNotification(put, {
      success: {
        title: 'Success',
        description: 'Update successful.',
      },
      error: {
        title: 'Update Failed',
      },
    });

    if (put.isSuccess) {
      const auth = JSON.parse(localStorage.getItem('auth') || '{}');
      localStorage.setItem(
        'auth',
        JSON.stringify({ ...auth, username: newEmail })
      );
      user.refetch();
      dismiss();
    }
  }, [put.isSuccess, put.isError]);

  return (
    <Modal
      title="Change Email"
      confirmLoading={put.isLoading}
      onOk={onOk}
      okButtonProps={{ disabled: !newEmail || !accountPassword }}
      okText="Save/Update"
      visible={visible}
      onCancel={dismiss}
    >
      <Form className="pt-3">
        <Form.Item>
          <label htmlFor="newEmail">New Email Address</label>
          <Input
            id="newEmail"
            type="email"
            value={newEmail}
            onChange={(event) => setNewEmail(event.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <label htmlFor="accountPassword">Current Account Password</label>
          <Input
            id="accountPassword"
            name="accountPassword"
            type="password"
            value={accountPassword}
            onChange={(event) => setAccountPassword(event.target.value)}
          />
        </Form.Item>
      </Form>

      <div className="alert alert-warning">
        <p className="pb-3">
          This email address is where STAR will send all account communication.
          It is also what you use to log in to your STAR Family Account.
        </p>
        <p>
          Logging in with your new email address will not work until it has been
          verified by email.
        </p>
      </div>
    </Modal>
  );
};

export default ChangeEmail;

import React, { FC } from 'react';
import { Button, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useGet } from '../../../../hooks/useApi';
import CreditCardDiv from '../../../../components/CreditCardDiv';

const AutoPaymentComponent: FC<{ onPaymentSuccess: () => void }> = () => {
  const navigate = useNavigate();
  const navigateToAutoPay = () => navigate('/autoPay');

  const check = useGet<any>('/v1/auth/dashboard/auto-pay/checkLoggedInUser');

  if (check.isLoading) {
    return <Skeleton active />;
  }

  if (check.data.activeScheduledPayment) {
    return (
      <>
        <div style={{ background: '#efefef', padding: '16px' }}>Auto Pay</div>

        <div className={'pt-4'}>
          <CreditCardDiv
            creditCard={{
              holderName:
                check.data?.defaultPaymentMethod?.billing_details?.name ||
                check.data?.customer?.name,
              number: `**** **** **** ${check.data?.defaultPaymentMethod?.card?.last4}`,
              expiryMonth: check.data?.defaultPaymentMethod?.card?.exp_month,
              expiryYear: check.data?.defaultPaymentMethod?.card?.exp_year,
              ccv: '',
              cpfCnpj: '',
              expiryDate: `${check.data?.defaultPaymentMethod?.card?.exp_month}/${check.data?.defaultPaymentMethod?.card?.exp_year}`,
              brand: check.data?.defaultPaymentMethod?.card?.brand,
            }}
          />
        </div>

        <div className={'pt-3 text-center'} style={{ color: 'green' }}>
          Tuition Auto-Pay is currently set up
        </div>

        <div className={'pt-4'}>
          <Button
            id={'pay-now'}
            onClick={navigateToAutoPay}
            block={true}
            type={'primary'}
          >
            Update Auto Payments
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ background: '#efefef', padding: '16px' }}>Auto Pay</div>

      <div className={'pt-3 text-center'} style={{ color: '#666' }}>
        Currently No Auto Pay Set
      </div>

      <div className={'pt-4'}>
        <Button
          id={'pay-now'}
          onClick={navigateToAutoPay}
          block={true}
          type={'primary'}
        >
          Set-Up Auto Payments
        </Button>
      </div>
    </>
  );
};

export default AutoPaymentComponent;

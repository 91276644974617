import React, { FC } from 'react';
import PageTitle from '../../layout/PageTitle/PageTitle';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { useGet } from '../../hooks/useApi';
import { Button, Skeleton } from 'antd';
import ChooseCreditCard from './components/ChooseCreditCard';
import { Col, Row } from 'react-bootstrap';

import './AutoPay.css';
import ConfirmPaymentPolicies from './components/ConfirmPaymentPolicies';
import AutoPayIsForTuitionOnly from './components/AutoPayIsForTuitionOnly';
import CurrentAutomaticPayment from './components/CurrentAutomaticPayment';

const AutoPay: FC = () => {
  const check = useGet<any>('/v1/auth/dashboard/auto-pay/checkLoggedInUser');

  const getCreditCards = () =>
    (check.data?.paymentMethods || []).filter((x) => x.type == 'card');

  const OnError = () => (
    <div className={'alert alert-danger'}>
      <table className={'w-100'}>
        <tr>
          <td>Error while loading the page</td>
          <td className={'text-right'} style={{ width: '100px' }}>
            <Button type={'primary'} onClick={() => check.refetch()}>
              Reload
            </Button>
          </td>
        </tr>
      </table>
    </div>
  );

  if (check.isLoading) {
    return (
      <SidebarPage>
        <PageTitle>{`AutoPay`}</PageTitle>
        <Skeleton active />
      </SidebarPage>
    );
  }

  if (!check.isSuccess) {
    return (
      <SidebarPage>
        <PageTitle>{`AutoPay`}</PageTitle>
        <OnError />
      </SidebarPage>
    );
  }

  if (!check.data.defaultPaymentMethod) {
    return (
      <SidebarPage>
        <PageTitle>{`AutoPay`}</PageTitle>
        <ChooseCreditCard
          customer={check.data?.customer}
          creditCards={getCreditCards()}
          creditCardAdded={() => check.refetch()}
          creditCardUpdated={() => check.refetch()}
          creditCardDeleted={() => check.refetch()}
        ></ChooseCreditCard>
      </SidebarPage>
    );
  }

  if (check.data.activeScheduledPayment) {
    return (
      <SidebarPage>
        <PageTitle>{`AutoPay`}</PageTitle>
        <Row id={'auto-pay-row1'}>
          <Col md={6}>
            <CurrentAutomaticPayment
              data={check.data}
              onDeAuthorize={() => check.refetch()}
            />
          </Col>
        </Row>
      </SidebarPage>
    );
  }

  return (
    <SidebarPage>
      <PageTitle>{`AutoPay`}</PageTitle>
      <Row id={'auto-pay-row1'}>
        <Col md={6}>
          <AutoPayIsForTuitionOnly />
        </Col>
        <Col md={6}>
          <ConfirmPaymentPolicies
            data={check.data}
            onDeAuthorize={() => check.refetch()}
            onAuthorize={() => check.refetch()}
          />
        </Col>
      </Row>
    </SidebarPage>
  );
};

export default AutoPay;

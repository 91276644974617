import { FC, useEffect, useState } from 'react';
import { Radio, Input, Alert, Skeleton, Button } from 'antd';

import { Form, Col } from 'react-bootstrap';

import { mutationNotification, usePost } from '../../../../hooks/useApi';

import { useFetchLedgerBalance } from '../../../../hooks/useFetchLedgerBalance';
import LedgerCurrentBalance from '../../components/CurrentBalance';

const MakeAPaymentComponent: FC<{ onPaymentSuccess: () => void }> = ({
  onPaymentSuccess,
}) => {
  const ledgerBalance = useFetchLedgerBalance();

  const [balance, setBalance] = useState(0);
  const [paymentOption, setPaymentOption] = useState(
    balance > 0 ? 'current' : 'custom'
  );
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const post = usePost<any, any>('/v1/auth/dashboard/payment');

  const dismiss = () => {
    onPaymentSuccess();
  };

  const onOk = () => {
    post.mutate({
      amount: paymentOption === 'current' ? +balance : +amount,
      payment: paymentOption,
      force: false,
    });
  };

  const handlePaymentChange = (e: any) => {
    setPaymentOption(e.target.value);
    if (e.target.value === 'current') {
      setAmount('');
    }
  };

  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);

    if (e.target.value < 5 || e.target.value > 5000) {
      setError('An amount between $5 and $5000 is required');
    } else {
      setError('');
    }
  };

  const canSubmit = () => {
    if (paymentOption === 'current') {
      return +balance > 0;
    } else {
      return +amount >= 5 && +amount <= 5000;
    }
  };

  useEffect(() => {
    const ledgerBalanceData = +(ledgerBalance.data || 0);

    setBalance(ledgerBalanceData);
    setPaymentOption(ledgerBalanceData > 0 ? 'current' : 'custom');
  }, [ledgerBalance.data]);

  useEffect(() => {
    mutationNotification(post, {
      success: {
        title: 'Success',
        description: 'You will be redirected to the payment page.',
      },
      error: {
        title: 'Payment Failed',
      },
    });

    if (post.isSuccess) {
      window.location.href = post.data.url;

      ledgerBalance.refetch();
      dismiss();
    }
  }, [post.isSuccess, post.isError]);

  if (ledgerBalance.isLoading) {
    return <Skeleton active />;
  }

  return (
    <>
      <div style={{ background: '#efefef', padding: '16px' }}>
        Make a Payment
      </div>

      <div className={'pt-3'}>
        <Radio.Group onChange={handlePaymentChange} value={paymentOption}>
          <Radio disabled={balance <= 0} value="current">
            Current Balance: <LedgerCurrentBalance />
          </Radio>
          <Radio value="custom">Choose Amount</Radio>
        </Radio.Group>

        {paymentOption === 'current' && (
          <>
            <Button
              loading={post.isLoading}
              disabled={!canSubmit()}
              id={'pay-now'}
              block={true}
              type={'primary'}
              onClick={onOk}
            >
              Pay Now
            </Button>

            {error && (
              <div className={'pt-3'}>
                <Alert message={error} type="error" />
              </div>
            )}
          </>
        )}

        {paymentOption === 'custom' && (
          <div id="amount">
            <Form.Group as={Col} controlId="amount-field">
              <Form.Label>Amount:</Form.Label>

              <table className={'w-100'}>
                <tr>
                  <td>
                    <Input
                      type="number"
                      name="amount"
                      placeholder="$0"
                      value={amount}
                      min={5}
                      max={5000}
                      onChange={handleAmountChange}
                      required
                    />
                  </td>
                  <td style={{ width: '140px', paddingLeft: '8px' }}>
                    <Button
                      loading={post.isLoading}
                      disabled={!canSubmit()}
                      id={'pay-now'}
                      block={true}
                      type={'primary'}
                      onClick={onOk}
                    >
                      Pay Now
                    </Button>
                  </td>
                </tr>
              </table>

              {error && (
                <div className={'pt-3'}>
                  <Alert message={error} type="error" />
                </div>
              )}
            </Form.Group>
          </div>
        )}
      </div>
    </>
  );
};

export default MakeAPaymentComponent;

import {
  Input,
  Modal,
  Skeleton,
  Checkbox,
  message,
  Switch,
  notification,
} from 'antd';
import { FC, useState, useEffect } from 'react';
import { useGet, usePost } from '../../../hooks/useApi';
import { Student } from '../../../models/student.model';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const PaymentModal: FC<{
  id: number;
  student: Student;
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
}> = ({ id, student, visible, onVisibleChange }) => {
  const navigate = useNavigate();
  const upcoming = useGet<any>(
    `/v1/auth/dashboard/nsd-camps/${id}/complete/fee-distribution/${student.id}`
  );
  const studentComplete = useGet<any>(
    `/v1/auth/dashboard/student/${student.id}`
  );
  const post = usePost<any, any>(`/v1/auth/dashboard/nsd-camps/payment`);

  const [selectedCampDays, setSelectedCampDays] = useState<{
    [key: number]: boolean;
  }>({});
  const [selectedAddons, setSelectedAddons] = useState<{
    [key: number]: boolean;
  }>({});
  const [total, setTotal] = useState(0);

  const getCampDayFee = (campDay: any) => {
    return parseFloat(campDay.feeDistribution?.familyAmount);
  };

  useEffect(() => {
    // Calculate the total whenever selections change
    let newTotal = 0;

    upcoming.data?.NSDCampDays.forEach((campDay: any) => {
      if (selectedCampDays[campDay.id]) {
        newTotal += getCampDayFee(campDay);

        campDay.NSDCampDayAddons.forEach((addon: any) => {
          const addonFee = parseFloat(
            addon.organizationExempt ? addon.amount : 0
          );

          newTotal += selectedAddons[addon.id] ? addonFee : 0;
        });
      }
    });

    setTotal(newTotal);
  }, [selectedCampDays, selectedAddons, upcoming.data]);

  useEffect(() => {
    if (post.isSuccess) {
      if (post.data.payment && post.data.payment.url) {
        notification.success({
          message: 'Success',
          description: 'You will be redirected to the payment page.',
          placement: 'topRight',
        });
        window.location.href = post.data.payment.url;
      } else {
        notification.success({
          message: 'Success',
          description: 'Your enrollment was registered.',
          placement: 'topRight',
        });
        navigate('/');
      }

      dismiss();
    }

    if (post.isError) {
      notification.error({
        message: 'Payment Failed',
        description: 'An error occurred. Please try again.',
        placement: 'topRight',
      });
    }
  }, [post.isSuccess, post.isError]);

  /**
   * Determines whether the user can submit based on the selected camp days.
   *
   * @returns {boolean} `true` if at least one camp day is selected, `false` otherwise.
   */
  const canSubmit = () => {
    const selectedDays = Object.keys(selectedCampDays).filter(
      (key) => selectedCampDays[parseInt(key)]
    );
    return selectedDays.length > 0;
  };

  /**
   * Updates the selected addons for a specific camp day based on its checked status.
   *
   * @param {number} campDayId - The ID of the camp day.
   * @param {boolean} isChecked - Whether the camp day is selected or not.
   */
  const updateCampDayAddons = (campDayId: number, isChecked: boolean) => {
    const campDay = upcoming.data?.NSDCampDays.find(
      (day: any) => day.id === campDayId
    );

    campDay?.NSDCampDayAddons.forEach((addon: any) => {
      const shouldSelectAddon = isChecked && !addon.optional;
      setSelectedAddons((prev) => ({ ...prev, [addon.id]: shouldSelectAddon }));
    });
  };

  /**
   * Handles the selection of a camp day and updates its associated addons.
   *
   * @param {number} campDayId - The ID of the camp day.
   * @param {boolean} isChecked - Whether the camp day is selected or not.
   */
  const handleCampDayChange = (campDayId: number, isChecked: boolean) => {
    setSelectedCampDays((prev) => ({ ...prev, [campDayId]: isChecked }));
    updateCampDayAddons(campDayId, isChecked);
  };

  /**
   * Handles the selection of an addon for a specific camp day.
   *
   * @param {number} addonId - The ID of the addon.
   * @param {boolean} checked - Whether the addon is selected or not.
   */
  const handleAddonChange = (addonId: number, checked: boolean) => {
    setSelectedAddons((prev) => ({ ...prev, [addonId]: checked }));
  };

  /**
   * Handles the confirmation action when the user submits their selected camp days.
   *
   * It checks if at least one camp day is selected. If no camp days are selected, a warning is displayed.
   * If valid camp days are selected, it prepares the camp days data with the selected addons and submits the data using the `post.mutate` function.
   *
   * @returns {void}
   */
  const onOk = () => {
    const selectedDays = Object.keys(selectedCampDays).filter(
      (key) => selectedCampDays[parseInt(key)]
    );
    if (selectedDays.length === 0) {
      message.warning('Please select at least one camp day');
      return;
    }

    const campDays = selectedDays.map((dayId) => {
      const campDay = upcoming.data.NSDCampDays.find(
        (day: any) => day.id === parseInt(dayId)
      );
      const addons = campDay.NSDCampDayAddons.filter(
        (addon: any) => selectedAddons[addon.id]
      );

      return {
        id: campDay.id,
        addons: addons.map((addon: any) => ({ id: addon.id })),
      };
    });

    post.mutate({
      studentId: +student.id,
      NSDCampId: +id,
      amount: +total,
      campDays: campDays,
    });
  };

  /**
   * Dismisses or hides the visible UI component (such as a modal) by setting the visibility state to false.
   *
   * @returns {void}
   */
  const dismiss = () => {
    onVisibleChange(false);
  };

  const Content = () => (
    <>
      <hr className={'mt-3 mb-3'} />

      <p>
        <b>{`Student's Name:`} </b> {student.firstName} {student.lastName}
      </p>
      <p>
        <b>Grade: </b> {studentComplete.data?.Grade?.grade}
      </p>

      {upcoming.data?.NSDCampDays.map((campDay: any) => {
        return (
          <div
            key={campDay.id}
            className="grid-x grid-padding-x grid-padding-y align-middle"
          >
            <hr className={'mt-3 mb-3'} />
            <div className="cell small-6">
              <h6 className={'mb-2'}>
                {moment(campDay.date).format('dddd, MMMM D')}
              </h6>
            </div>

            <table className={'w-100'}>
              <tr>
                <td style={{ verticalAlign: 'middle' }}>
                  <Switch
                    defaultValue={selectedCampDays[campDay.id] || false}
                    onChange={(e) => handleCampDayChange(campDay.id, e)}
                  ></Switch>
                  <span className={'ml-2'}>
                    Care (${getCampDayFee(campDay).toFixed(2)})
                  </span>
                </td>
                <td>
                  {campDay.NSDCampDayAddons &&
                    campDay.NSDCampDayAddons.length > 0 && (
                      <>
                        <div className="ml-6">
                          Add-Ons:
                          {campDay.NSDCampDayAddons.map((addon: any) => {
                            const addonFee = parseFloat(
                              addon.organizationExempt ? addon.amount : 0
                            );
                            return (
                              <div
                                className="cell auto text-center"
                                key={addon.id}
                              >
                                <Checkbox
                                  checked={
                                    !addon.optional ||
                                    selectedAddons[addon.id] ||
                                    false
                                  }
                                  onChange={(e) =>
                                    handleAddonChange(
                                      addon.id,
                                      e.target.checked
                                    )
                                  }
                                  disabled={
                                    !selectedCampDays[campDay.id] ||
                                    !addon.optional
                                  }
                                >
                                  {addon.description} (${addonFee.toFixed(2)}){' '}
                                  {addon.optional && <small>Optional</small>}
                                </Checkbox>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                </td>
              </tr>
            </table>
          </div>
        );
      })}

      <hr className={'mt-3 mb-3'} />

      <table className={'w-100'}>
        <tr>
          <td style={{ width: '50px' }}>Total:</td>
          <td>
            <Input type={'text'} value={`$${total.toFixed(2)}`} disabled />
          </td>
        </tr>
      </table>
    </>
  );

  return (
    <Modal
      title="Choose Your Payment"
      confirmLoading={post.isLoading}
      onOk={onOk}
      okText={total > 0 ? 'Pay Now' : 'Enroll'}
      open={visible}
      onCancel={dismiss}
      okButtonProps={{ disabled: !canSubmit(), id: 'pay-now' }}
      className={'make-a-payment-modal'}
    >
      {upcoming.data ? <Content /> : <Skeleton active />}
    </Modal>
  );
};

export default PaymentModal;

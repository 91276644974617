// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#auto-pay-row1 p {
  margin-bottom: 16px;
}
#auto-pay-row1 hr {
  margin: 16px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AutoPay/AutoPay.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB","sourcesContent":["#auto-pay-row1 p {\n  margin-bottom: 16px;\n}\n#auto-pay-row1 hr {\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { FC, useState, useEffect } from 'react';
import { Form, Input, Button, Card, Alert, Checkbox } from 'antd';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { mutationNotification, useGet, usePut } from '../../hooks/useApi';
import PageTitle from '../../layout/PageTitle/PageTitle';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useFetchUser } from '../../hooks/useFetchUser';
import { EmergencyContact as EmergencyContactModel } from '../../models/emergency-contact.model';

const EmergencyContact: FC = () => {
  const { id } = useParams<{ id: string }>();
  const user = useFetchUser();

  const emergencyContact = useGet<EmergencyContactModel>(
    `/v1/auth/dashboard/emergency-contact/${id}`
  );
  const mutation = usePut<any, any>(
    `/v1/auth/dashboard/emergency-contact/${id}`
  );

  const [form] = Form.useForm();
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    if (emergencyContact.data) {
      form.setFieldsValue(emergencyContact.data);
    }
  }, [emergencyContact.data]);

  useEffect(
    () =>
      mutationNotification(mutation, {
        success: {
          title: 'Update Successful',
          description:
            'The emergency contact information has been updated successfully.',
        },
        error: {
          title: 'Update Failed',
        },
      }),
    [mutation.isSuccess, mutation.isError]
  );

  const onFinish = (values: Partial<EmergencyContactModel>) => {
    mutation.mutate(values);
    setFormError(null);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setFormError(
      'Please double-check the entire form for missing required information and then try saving the information again.'
    );
  };

  const Box = () => (
    <div>
      <div
        style={{
          backgroundColor: '#eadef3',
          padding: '1rem',
          border: '1px solid rgba(10,10,10,.25)',
          fontSize: '16px',
        }}
      >
        <p className={'pb-3'}>
          Emergency contacts are commonly used for identifying who is able to
          pick up your student.
        </p>
        <p className={'pb-3'}>
          In an emergency, we will first try contacting the primary guardian.
          Then, we will try the additional guardians you have listed for a
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          student. After that, we will then start calling the student's emergecy
          contacts.
        </p>
      </div>
    </div>
  );

  const OnError = () => (
    <div className={'alert alert-danger'}>
      <table className={'w-100'}>
        <tr>
          <td>Error while loading the page</td>
          <td className={'text-right'} style={{ width: '100px' }}>
            <Button type={'primary'} onClick={() => emergencyContact.refetch()}>
              Reload
            </Button>
          </td>
        </tr>
      </table>
    </div>
  );

  const EmergencyContactForm = () => (
    <Card
      loading={emergencyContact.isLoading || mutation.isLoading}
      title="Emergency Contact"
      bordered={false}
    >
      <Form
        form={form}
        name="emergency"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'First Name is required' }]}
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Last Name is required' }]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            {
              required: true,
              pattern: /^\d{3}[- ]\d{3}[- ]\d{4}$/,
              message: 'Required Format: XXX-XXX-XXXX',
            },
          ]}
        >
          <Input placeholder="Phone Number" />
        </Form.Item>
        <Form.Item
          label="Driver's License"
          name="driversLicense"
          rules={[{ required: true, message: "Driver's License is required" }]}
        >
          <Input placeholder="Driver's License" />
        </Form.Item>
        <Form.Item name="canPickup" valuePropName="checked">
          <Checkbox>Can Pick Up?</Checkbox>
        </Form.Item>
        {formError && <Alert message={formError} type="error" showIcon />}
        <Button block size={'large'} type="primary" htmlType="submit">
          Save/Update
        </Button>
      </Form>
    </Card>
  );

  return (
    <SidebarPage>
      <PageTitle>
        {user.isLoading
          ? 'Loading...'
          : (user.data?.PrimaryGuardian?.lastName || '') +
            ' Family Account: Emergency Contacts'}
      </PageTitle>

      <Row>
        <Col md={6}>
          {emergencyContact.isError && <OnError />}
          <EmergencyContactForm />
        </Col>
        <Col md={6}>
          <Box />
        </Col>
      </Row>
    </SidebarPage>
  );
};

export default EmergencyContact;

import React, { FC, useEffect, useState } from 'react';
import { Button, Card, DatePicker, Skeleton } from 'antd';
import CreditCardDiv from '../../../components/CreditCardDiv';
import { mutationNotification, useDelete, usePut } from '../../../hooks/useApi';
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import PaymentProcessingDisclosureModal from '../modals/PaymentProcessingDisclosureModal';

type ConfirmPaymentPoliciesProps = {
  data: any;
  onDeAuthorize: () => void;
  onAuthorize: () => void;
};

const ConfirmPaymentPolicies: FC<ConfirmPaymentPoliciesProps> = ({
  data,
  onDeAuthorize,
  onAuthorize,
}) => {
  const [
    paymentProcessingDisclosureModalvisible,
    setPaymentProcessingDisclosureModalvisible,
  ] = useState(false);

  const [endDate, setEndDate] = useState<Date | null>(null);

  const openPaymentProcessingDisclosureModal = (ev: any) => {
    ev.preventDefault();
    setPaymentProcessingDisclosureModalvisible(true);
  };

  const confirmIntentPut = usePut<any, any>(
    '/v1/auth/dashboard/auto-pay/finalizeIntent/' + data.defaultPaymentMethod.id
  );

  useEffect(() => {
    mutationNotification(confirmIntentPut, {
      success: {
        title: 'Success',
        description: 'Payment authorized successfully',
      },
      error: {
        title: 'Error authorizing payment method',
      },
    });

    if (confirmIntentPut.isSuccess) {
      onAuthorize();
    }
  }, [confirmIntentPut.isSuccess, confirmIntentPut.isError]);

  const handleAuthorize = async (ev) => {
    ev.preventDefault();

    if (!endDate) {
      return;
    }

    confirmIntentPut.mutate({
      endDate: moment(endDate).toISOString(),
    });
  };

  const removeIntentDelete = useDelete<any>(
    '/v1/auth/dashboard/auto-pay/removeIntent/' + data.defaultPaymentMethod.id
  );

  useEffect(() => {
    mutationNotification(removeIntentDelete, {
      success: {
        title: 'Success',
        description: 'Payment de-authorized successfully',
      },
      error: {
        title: 'Error de-authorizing payment method',
      },
    });

    if (removeIntentDelete.isSuccess) {
      onDeAuthorize();
    }
  }, [removeIntentDelete.isSuccess, removeIntentDelete.isError]);

  const handleDeAuthorize = async (ev) => {
    ev.preventDefault();

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to de-autorize?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      confirmButtonColor: 'red',
    });

    if (result.isConfirmed) {
      removeIntentDelete.mutate({});
    }
  };

  if (removeIntentDelete.isLoading) {
    return (
      <Card id={'cofirm-payment-policies'} title={'Confirm Payment Policies'}>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <>
      <Card id={'cofirm-payment-policies'} title={'Confirm Payment Policies'}>
        <p>
          This is the card that you authorized for automatic payments. If you
          need to use a different card, you will need to first de-authorize this
          one.
        </p>

        <hr />

        <div className={'pt-3'}>
          <CreditCardDiv
            creditCard={{
              holderName:
                data?.defaultPaymentMethod?.billing_details?.name ||
                data?.customer.name,
              number: `**** **** **** ${data?.defaultPaymentMethod.card.last4}`,
              expiryMonth: data?.defaultPaymentMethod.card.exp_month,
              expiryYear: data?.defaultPaymentMethod.card.exp_year,
              ccv: '',
              cpfCnpj: '',
              expiryDate: `${data?.defaultPaymentMethod.card.exp_month}/${data?.defaultPaymentMethod.card.exp_year}`,
              brand: data?.defaultPaymentMethod.card.brand,
            }}
          />
        </div>

        <div className={'text-center pt-3 pb-3'}>
          <Button
            style={{ width: '257px' }}
            type={'dashed'}
            onClick={handleDeAuthorize}
          >
            De-Authorize?
          </Button>
        </div>

        <hr />

        <p>
          If you {`haven't`} already, please take a moment to read our{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={openPaymentProcessingDisclosureModal}
            href={'#'}
            style={{ color: '#703895' }}
          >
            Payment Processing Disclosure
          </a>
          .
        </p>

        <p>
          All scheduled payments must have an end date. Payments will occur
          monthly until the date that you select.
        </p>

        {!endDate && <p style={{ color: 'red' }}>Please select an end date.</p>}

        <Row>
          <Col md={3}>
            <DatePicker
              className={'w-100'}
              value={endDate ? moment(endDate) : null} // Convert to moment object if endDate exists
              onChange={(date) => setEndDate(date ? date.toDate() : null)} // Convert back to Date when updating
              picker={'date'}
            ></DatePicker>
          </Col>
          <Col md={9}>
            <Button
              type={'primary'}
              onClick={handleAuthorize}
              block
              disabled={!endDate}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Card>

      <PaymentProcessingDisclosureModal
        visible={paymentProcessingDisclosureModalvisible}
        onClose={() => setPaymentProcessingDisclosureModalvisible(false)}
      />
    </>
  );
};

export default ConfirmPaymentPolicies;

import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Button, Alert, Radio, Skeleton } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { Medication } from '../../../../models/medication.model';
import {
  mutationNotification,
  usePost,
  usePut,
} from '../../../../hooks/useApi';

interface StudentMedicationProps {
  studentId: number;
  medication: Partial<Medication>;
  updated?: (medication: Medication) => any;
  created?: (medication: Medication) => any;
}

const StudentMedication: FC<StudentMedicationProps> = ({
  studentId,
  medication,
  updated,
  created,
}) => {
  const [form] = useForm();
  const [formError, setFormError] = useState<string | null>(null);

  const put = usePut<any, any>(
    '/v1/auth/dashboard/medication/' + medication.id
  );
  const post = usePost<any, any>(
    '/v1/auth/dashboard/medications/student/' + studentId
  );

  useEffect(() => {
    form.setFieldsValue({
      ...medication,
      startDate: medication.startDate ? moment(medication.startDate) : null,
      endDate: medication.endDate ? moment(medication.endDate) : null,
      atStar: medication.atStar ? 'true' : 'false',
    });
  }, [medication, form]);

  useEffect(() => {
    if (put.isSuccess && updated) {
      updated(put.data);
    }

    mutationNotification(put, {
      success: {
        title: 'Success',
        description: 'Medication information has been updated successfully.',
      },
      error: {
        title: 'Update Failed',
      },
    });
  }, [put.isSuccess, put.isError]);

  useEffect(() => {
    if (post.isSuccess && created) {
      created(post.data);
    }

    mutationNotification(post, {
      success: {
        title: 'Success',
        description: 'Medication information has been created successfully.',
      },
      error: {
        title: 'Update Failed',
      },
    });
  }, [post.isSuccess, post.isError]);

  const onFinish = (values: any) => {
    const medicationNewData: Partial<Medication> = values;

    medicationNewData.atStar = values.atStar === 'true';

    (medication.id ? put : post).mutate(medicationNewData);
    setFormError(null);
  };

  const onFinishFailed = (errorInfo: any) => {
    // Handle form submission failure
    console.log(errorInfo);
    setFormError('Form submission failed');
  };

  if (post.isLoading || put.isLoading) {
    return <Skeleton active />;
  }

  return (
    <Form
      form={form}
      name="medication"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        ...medication,
        startDate: medication.startDate ? moment(medication.startDate) : null,
        endDate: medication.endDate ? moment(medication.endDate) : null,
        atStar: medication.atStar ? 'true' : 'false',
      }}
    >
      <Form.Item
        label="Name of Medication"
        name="name"
        rules={[{ required: true, message: 'Name of Medication is required' }]}
      >
        <Input placeholder="Name of Medication" />
      </Form.Item>
      <Form.Item
        label="Dosage"
        name="dosage"
        rules={[{ required: true, message: 'Dosage is required' }]}
      >
        <Input placeholder="Dosage" />
      </Form.Item>
      <Form.Item
        label="Taken For?"
        name="takenFor"
        rules={[{ required: true, message: 'Taken For is required' }]}
      >
        <Input placeholder="Taken For?" />
      </Form.Item>
      <Form.Item
        label="Prescribed By?"
        name="doctor"
        rules={[{ required: true, message: 'Prescribed By is required' }]}
      >
        <Input placeholder="Prescribed By?" />
      </Form.Item>
      <Form.Item
        label="Phone Number"
        name="doctorPhone"
        rules={[{ required: true, message: 'Phone Number is required' }]}
      >
        <Input placeholder="Phone Number" />
      </Form.Item>
      <Form.Item
        label="Taken AT STAR?"
        name="atStar"
        rules={[{ required: true, message: 'Taken AT STAR is required' }]}
      >
        <Radio.Group>
          <Radio value="true">Yes</Radio>
          <Radio value="false">No</Radio>
        </Radio.Group>
      </Form.Item>
      {formError && <Alert message={formError} type="error" showIcon />}
      <Button block size="large" type="primary" htmlType="submit">
        Save/Update
      </Button>
    </Form>
  );
};

export default StudentMedication;

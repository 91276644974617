import React, { FC } from 'react';
import { Button, Card } from 'antd';

const RegisterDiv: FC = () => {
  const goToRegister = (ev: any) => {
    ev.preventDefault();
    window.location.href = 'https://accounts.starsacramento.org/';
  };

  return (
    <Card className={'text-center mt-3'}>
      <p className={'pb-3'}>
        <b>Need a account?</b>
      </p>
      <Button
        onClick={goToRegister}
        type="primary"
        style={{ backgroundColor: '#6cc069' }}
        block
        size={'large'}
      >
        Register Now
      </Button>
    </Card>
  );
};

export default RegisterDiv;

import React, { ReactNode, useState } from 'react';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { default as TemplateHeader } from '../Header/Header';

const { Content, Sider } = Layout;

import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { useFetchUser } from '../../hooks/useFetchUser';

import swal from 'sweetalert';

interface PageProps {
  children: ReactNode;
}

const SidebarPage: React.FC<PageProps> = ({ children }) => {
  const user = useFetchUser();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const primaryRequired = () => {
    navigate('/primary');

    swal({
      icon: 'error',
      title: 'Primary Guardian Required',
      text: 'Please complete the Primary Guardian section before proceeding.',
    });
  };

  // @ts-ignore
  const getAntdMenuItems: ItemType<MenuItemType>[] = () => {
    const primary = user.data && user.data.PrimaryGuardian;
    return constants.menuItems.map((item) => ({
      ...item,
      disabled: item.primaryRequired && !primary,
      onClick: () =>
        !item.primaryRequired || (item.primaryRequired && primary)
          ? navigate(item.path)
          : primaryRequired(),
    }));
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <TemplateHeader></TemplateHeader>
      <Layout>
        {window.innerWidth >= 767 && (
          <Sider
            width={230}
            trigger={null}
            style={{ backgroundColor: 'rgb(31, 155, 214)' }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div className="demo-logo-vertical" />
            <div style={{ padding: '2rem 1rem' }}>
              <table className={'w-100'}>
                {collapsed && (
                  <tr>
                    <td
                      className={'text-center'}
                      style={{
                        fontSize: '22px',
                        verticalAlign: 'middle',
                        color: '#fff',
                      }}
                    >
                      <RightCircleFilled onClick={() => setCollapsed(false)} />
                    </td>
                  </tr>
                )}
                {!collapsed && (
                  <tr>
                    <td>
                      <h3
                        style={{
                          fontSize: '1.9375rem',
                          color: '#fff',
                          verticalAlign: 'middle',
                        }}
                      >
                        Menu
                      </h3>
                    </td>
                    <td
                      className={'text-right'}
                      style={{
                        fontSize: '22px',
                        verticalAlign: 'middle',
                        paddingTop: '6px',
                        color: '#fff',
                      }}
                    >
                      <LeftCircleFilled onClick={() => setCollapsed(true)} />
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <Menu
              theme="dark"
              style={{ backgroundColor: 'rgb(31, 155, 214)' }}
              defaultSelectedKeys={['1']}
              mode="inline"
              items={getAntdMenuItems()}
            />
          </Sider>
        )}
        <Layout>
          <Content style={{ padding: '24px' }}>{children}</Content>
        </Layout>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default SidebarPage;

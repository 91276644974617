// src/components/AuthGuard.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useContextValue } from '../Context';

interface AuthGuardProps {
  children?: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { state } = useContextValue();

  if (!state.user?.id) {
    return <Navigate to="/login" replace />;
  }

  return <>{children || <Outlet />}</>;
};

export default AuthGuard;

import { Card } from 'antd';
import React from 'react';

const AutoPayIsForTuitionOnly = () => {
  return (
    <Card
      id={'auto-pay-is-for-tuition-only'}
      title={'Auto-Pay is For Tuition Only'}
    >
      <p>
        Automatic payments will only cover regular tuition payments. These come
        in two varieties.
      </p>
      <hr />
      <p>
        The first is <strong>scheduled tuition</strong>. Unless you make
        arrangements with your STAR Site Director, monthly tuition is billed on
        the first of the month and your payment will be charged approximately 24
        hours later.
      </p>
      <p>
        If you need a split payment or need to change the date of your payment,
        you will need to contact your STAR Site Director.
      </p>
      <p>
        You do not currently have any scheduled monthly tuition. Some programs
        do not have scheduled monthly tuition, they are instead charged on the
        first day of class.
      </p>
      <hr />
      <p>
        The second type is <strong>single payment tuition</strong>. These are
        always billed on the first day of the class or camp and your payment
        will be charged approximately 24 hours later.
      </p>
      <p>Nova classes and Summer Camps are typically charged this way.</p>

      <hr />
      <p>
        * The AutoPay system will check each day, plus up to two days prior, for
        any tuition fees that are currently due.
      </p>
    </Card>
  );
};

export default AutoPayIsForTuitionOnly;

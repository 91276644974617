// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-empty-image img {
  margin-left: auto;
  margin-right: auto;
}

.enrollments-div h5 {
  font-family: Oswald;
  color: #703895;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.5625rem;
}

.enrollments-div a.link {
  color: #703895;
}

.enrollments-div hr {
  margin: 16px 0;
}

.enrollments-div table {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Student/List/EnrollmentsDiv.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".ant-empty-image img {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.enrollments-div h5 {\n  font-family: Oswald;\n  color: #703895;\n  margin-top: 0;\n  margin-bottom: 16px;\n  font-size: 1.5625rem;\n}\n\n.enrollments-div a.link {\n  color: #703895;\n}\n\n.enrollments-div hr {\n  margin: 16px 0;\n}\n\n.enrollments-div table {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

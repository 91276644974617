import axios, { AxiosInstance } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3011',
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;

import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LoginDiv from './components/LoginDiv';
import RegisterDiv from './components/RegisterDiv';
import SimplePage from '../../layout/SimplePage/SimplePage';

const LoginPage: FC = () => {
  return (
    <SimplePage>
      <Container style={{ paddingTop: '16px' }}>
        <Row>
          <Col md={3}></Col>
          <Col md={6}>
            <LoginDiv />
            <RegisterDiv />
          </Col>
        </Row>
      </Container>
    </SimplePage>
  );
};

export default LoginPage;

import { FC } from 'react';
import PageTitle from '../../layout/PageTitle/PageTitle';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { useParams } from 'react-router-dom';
import { Student as StudentModel } from '../../models/student.model';
import { useGet } from '../../hooks/useApi';
import { Card, Skeleton } from 'antd';
import EnrollmentsDiv from '../Student/List/EnrollmentsDiv';

const StudentSchedule: FC = () => {
  const { id } = useParams<{ id: string }>();

  const api = useGet<StudentModel>('/v1/auth/dashboard/student/' + id, {
    enabled: !!id,
  });

  return (
    <SidebarPage>
      <PageTitle>
        {api.isLoading ? 'Loading...' : api.data?.firstName + "'s Schedule"}
      </PageTitle>

      {api.isLoading && <Skeleton active={true} />}

      {api.data && (
        <Card>
          <EnrollmentsDiv student={api.data} />
        </Card>
      )}
    </SidebarPage>
  );
};

export default StudentSchedule;

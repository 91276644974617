import { FC } from 'react';
import { Button, Card, Skeleton } from 'antd';
import { useGet } from '../../../hooks/useApi';
import { Table } from 'react-bootstrap';
import './List.css';
import { useNavigate } from 'react-router-dom';
import { Student } from '../../../models/student.model'; // Assuming the model is stored here
import constants from '../../../constants';
import moment from 'moment';
import EnrollmentsDiv from './EnrollmentsDiv';

interface StudentDivProps {
  student: Partial<Student> & { id: number };
  schedule?: boolean;
}

const StudentDiv: FC<StudentDivProps> = ({ student, schedule }) => {
  const navigate = useNavigate();

  const isEnrollable = useGet<{
    enrollable: boolean;
    hasMedicalInfo: boolean;
    emergencyContacts: boolean;
    additionalGuardians: boolean;
    sharingInfo: boolean;
  }>(`/v1/auth/dashboard/student/${student.id}/is-enrollable`);

  const onEdit = (id: number) => {
    navigate(`/student/${id}`);
  };

  // const OnDelete = (id: number) => {
  //   useDelete(`/v1/auth/dashboard/students/${id}`, {
  //     onSuccess: () => {
  //       onDeleted();
  //       notification.success({
  //         message: 'Success',
  //         description: 'Student deleted successfully',
  //       });
  //     },
  //     onError: (err) =>
  //       notification.error({
  //         message: 'Error',
  //         description: getErrorMessage(err.response),
  //       }),
  //   });
  // };

  const CardExtra: FC<{ id: number }> = ({ id }) => (
    <>
      <Button type={'link'} onClick={() => onEdit(id)}>
        Edit
      </Button>

      {/*<Popconfirm*/}
      {/*  title="Delete"*/}
      {/*  description="Are you sure to delete this?"*/}
      {/*  onConfirm={() => OnDelete(id)}*/}
      {/*  okText="Yes"*/}
      {/*  cancelText="No"*/}
      {/*>*/}
      {/*  <Button type={'link'} style={{ color: 'red' }}>*/}
      {/*    Delete*/}
      {/*  </Button>*/}
      {/*</Popconfirm>*/}
    </>
  );

  return (
    <Card
      className={'student-card'}
      title={`${student.firstName} ${student.lastName}`}
      extra={<CardExtra id={student.id} />}
    >
      {isEnrollable.isSuccess && (
        <div
          className={
            isEnrollable.data.enrollable
              ? 'alert alert-success'
              : 'alert alert-warning'
          }
        >
          {isEnrollable.data.enrollable ? (
            <>
              <i className="fa fa-check-circle card-message-icon" /> Information
              Is Complete For This Student
            </>
          ) : (
            <>
              {' '}
              <i className="fa fa-exclamation-triangle" /> Information
              Incomplete For This Student
            </>
          )}
        </div>
      )}

      <div className="card-section">
        {schedule !== true && (
          <>
            {isEnrollable.isSuccess && isEnrollable.data.enrollable && (
              <div className={'pb-2'}>
                <a className={'link'} href={`/student/${student.id}/schedule`}>
                  View/Edit Schedule
                </a>
              </div>
            )}
          </>
        )}
        <Table className={'text-center'}>
          <tbody>
            <tr>
              {student.birthdate && (
                <td>
                  Age
                  <h5>
                    {new Date().getFullYear() -
                      moment(student.birthdate).toDate().getFullYear()}
                  </h5>
                </td>
              )}
              <td>
                School
                <h5>{student.School ? student.School.shortName : 'N/A'}</h5>
              </td>
              <td>
                Grade
                <h5>{student.Grade ? student.Grade.shortGrade : 'N/A'}</h5>
              </td>
              {student.birthdate && (
                <td>
                  Birthdate
                  <h5>{moment(student.birthdate).format('MM/DD/YY')}</h5>
                </td>
              )}
            </tr>
          </tbody>
        </Table>

        {isEnrollable.isLoading && <Skeleton active />}
        {isEnrollable.isSuccess &&
          isEnrollable.data.enrollable &&
          !!constants.programs.length && (
            <>
              <h5>Enroll In A Program</h5>

              {constants.programs.map((program, index) => (
                <div key={`${program.name}-${index}`} className={'pb-2'}>
                  <Button
                    type={'primary'}
                    block
                    href={program.href}
                    target="_blank"
                    className="expanded"
                  >
                    {program.name}
                  </Button>
                </div>
              ))}

              <hr />
            </>
          )}

        {isEnrollable.isSuccess &&
          (!isEnrollable.data.enrollable ? (
            <Button
              type={'primary'}
              block
              onClick={() => onEdit(student.id)}
              className="expanded"
            >
              Complete Student Information
            </Button>
          ) : (
            <>{schedule === true && <EnrollmentsDiv student={student} />}</>
          ))}
      </div>
    </Card>
  );
};

export default StudentDiv;

import { FC } from 'react';
import { Button, Card, Skeleton, Result } from 'antd';
import SidebarPage from '../../../layout/SidebarPage/SidebarPage';
import { useGet } from '../../../hooks/useApi';
import PageTitle from '../../../layout/PageTitle/PageTitle';
import { Col, Row } from 'react-bootstrap';
import './List.css';
import { useFetchUser } from '../../../hooks/useFetchUser';
import { useNavigate } from 'react-router-dom';
import { UserAddOutlined } from '@ant-design/icons';
import { Student } from '../../../models/student.model'; // Assuming the model is stored here
import StudentDiv from './StudentDiv';

interface StudentListProps {
  mode: 'complete' | 'component';
  schedule?: boolean;
}

const StudentList: FC<StudentListProps> = ({
  mode = 'complete',
  schedule = true,
}) => {
  const navigate = useNavigate();
  const user = useFetchUser();
  const { data, isLoading /*, isError, refetch */ } = useGet<Student[]>(
    '/v1/auth/dashboard/students'
  );

  const onNew = () => {
    navigate(`/student/new`);
  };

  const OnEmpty = () => (
    <Result
      status="404"
      title="No students added yet"
      extra={
        <Button onClick={() => onNew()} type="primary">
          Add A Student
        </Button>
      }
    />
  );

  // const OnError = () => (
  //   <Result
  //     status="500"
  //     title="Error"
  //     subTitle="Error while loading the page"
  //     extra={
  //       <Button onClick={() => refetch()} type="primary">
  //         Try Again
  //       </Button>
  //     }
  //   />
  // );

  const Content = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    // if (isError) {
    //   return OnError();
    // }

    if ((data || []).length <= 0) {
      return OnEmpty();
    }

    return (
      <>
        <Row className="pt-4">
          {data?.map((student) => (
            <Col key={student.id} md={4} className={'pb-4'}>
              <StudentDiv student={student} schedule={schedule} />
            </Col>
          ))}

          <Col md={4}>
            <Card>
              <Result
                icon={<UserAddOutlined />}
                title="Student"
                style={{ padding: '72px' }}
                subTitle=""
                extra={[
                  <Button onClick={() => onNew()} type="primary" key="console">
                    Add New
                  </Button>,
                ]}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  return mode == 'component' ? (
    <Content />
  ) : (
    <SidebarPage>
      <PageTitle>
        {user.isLoading
          ? 'Loading...'
          : `${user.data?.PrimaryGuardian?.lastName || ''} Family Account: Students`}
      </PageTitle>

      <Content />
    </SidebarPage>
  );
};

export default StudentList;

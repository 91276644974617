import React, { FC } from 'react';
import { Modal } from 'antd';

const PaymentProcessingDisclosureModal: FC<{
  visible: boolean;
  onClose: () => void;
}> = ({ visible, onClose }) => {
  const closeModal = () => {
    onClose();
  };

  return (
    <Modal
      id={'payment-processing-disclosure-modal'}
      title={'Payment Processing Disclosure'}
      open={visible}
      onOk={closeModal}
      onCancel={closeModal}
    >
      <p className={'pt-3 pb-2'}>
        We use Stripe for payment, analytics, and other business services.
      </p>
      <p className={'pb-2'}>
        Stripe collects and processes personal data, including identifying
        information about the devices that connect to its services.
      </p>
      <p className={'pb-2'}>
        Stripe uses this information to operate and improve the services it
        provides to us, including for fraud detection and prevention.
      </p>
      <p>
        You can learn more about Stripe and its processing activities via
        privacy policy at https://stripe.com/privacy.
      </p>
    </Modal>
  );
};

export default PaymentProcessingDisclosureModal;

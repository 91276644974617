import React, { FC } from 'react';
import './CreditCardDiv.css';

interface CreditCard {
  holderName: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  ccv: string;
  cpfCnpj: string;
  expiryDate: string;
  brand?: CreditCardBrand; // Optional brand prop
}

type CreditCardBrand =
  | 'amex'
  | 'visa'
  | 'mastercard'
  | 'discover'
  | 'diners'
  | 'jcb'
  | 'unionpay'
  | 'unknown';

interface CreditCardDivProps {
  creditCard: CreditCard;
  center?: boolean;
  face?: 'front' | 'back';
}

const CreditCardDiv: FC<CreditCardDivProps> = ({
  creditCard,
  center = true,
  face = 'front',
}) => {
  const getCreditCardBrand = (): string => {
    // If brand is explicitly passed, use it
    if (creditCard.brand) {
      switch (creditCard.brand) {
        case 'amex':
          return 'ui-card ui-card__brand-amex ui-card__brand-american-express';
        case 'visa':
          return 'ui-card ui-card__brand-visa';
        case 'mastercard':
          return 'ui-card ui-card__brand-master ui-card__brand-mastercard';
        case 'discover':
          return 'ui-card ui-card__brand-discover';
        case 'diners':
          return 'ui-card ui-card__brand-diners';
        case 'jcb':
          return 'ui-card ui-card__brand-jcb';
        case 'unionpay':
          return 'ui-card ui-card__brand-unionpay';
        default:
          return 'ui-card';
      }
    }

    // Discover the brand based on the card number if not explicitly provided
    const number = creditCard.number || '';
    if (/^3[47][0-9]{5,}$/.test(number)) {
      return 'ui-card ui-card__brand-amex ui-card__brand-american-express';
    }
    if (/^4[0-9]{6,}$/.test(number)) {
      return 'ui-card ui-card__brand-visa';
    }
    if (
      /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
        number
      )
    ) {
      return 'ui-card ui-card__brand-master ui-card__brand-mastercard';
    }
    if (/^6(?:011|5[0-9]{2})[0-9]{12,}$/.test(number)) {
      return 'ui-card ui-card__brand-discover';
    }
    if (/^3(?:0[0-5]|[68][0-9])[0-9]{11,}$/.test(number)) {
      return 'ui-card ui-card__brand-diners';
    }
    if (/^(?:2131|1800|35\d{3})\d{11,}$/.test(number)) {
      return 'ui-card ui-card__brand-jcb';
    }
    if (/^62[0-9]{14,17}$/.test(number)) {
      return 'ui-card ui-card__brand-unionpay';
    }

    return 'ui-card';
  };

  const getCardNumberLen = (len: number): string => {
    const number = (creditCard.number || '').split(' ').join('');
    const arr = number.split('');
    return arr[len] || '';
  };

  const getExpiryLen = (len: number): string => {
    const validThrough = (creditCard?.expiryDate || '').replace('/', '');
    const arr = validThrough.split('');
    return arr[len] || '';
  };

  return (
    <div
      className="card__container"
      style={{ fontFamily: 'Roboto Mono, monospace' }}
    >
      <div className="ui-cards-container">
        <div data-component="ui-cards">
          <div data-js="ui-credit-card-component">
            <div
              className="new-card__container--view"
              style={{
                opacity: 1,
                transform: 'matrix(1, 0, 0, 1, 0, 0)',
              }}
            >
              <div
                id="ui-card"
                className={getCreditCardBrand()}
                style={
                  center ? { marginLeft: 'auto', marginRight: 'auto' } : {}
                }
              >
                <div
                  id="ui-card__rotate"
                  className={
                    face === 'front'
                      ? 'ui-card__rotate ui-card__rotate-front'
                      : 'ui-card__rotate ui-card__rotate-back'
                  }
                >
                  {/* Front */}
                  <div className="ui-card__container ui-card__front">
                    <div className="ui-card__background"></div>
                    <div className="ui-card__background-overlay"></div>
                    {/* Logo */}
                    <div className="ui-card__row">
                      <div className="ui-card__col-4 ui-card__logos-container">
                        <div id="ui-card__logo" className="ui-card__logo"></div>
                        <div
                          id="ui-card__logo_bank"
                          className="ui-card__logo-bank"
                        ></div>
                      </div>
                    </div>
                    {/* Security code */}
                    <div className="ui-card__row">
                      <div className="ui-card__col-4">
                        <div
                          id="ui-card__security-front"
                          className="ui-card__security ui-card__security-front"
                          style={{ visibility: 'hidden' }}
                        >
                          <span className="ui-card__point"></span>
                          <span className="ui-card__point"></span>
                          <span className="ui-card__point"></span>
                          <span className="ui-card__point"></span>
                        </div>
                      </div>
                    </div>
                    {/* Number */}
                    <div className="ui-card__row">
                      <div className="ui-card__col-4">
                        <div
                          id="ui-card__number"
                          className="ui-card__number ui-card__number-4-4-4-4"
                        >
                          {Array.from({ length: 19 }, (_, idx) => (
                            <span key={idx} className="ui-card__point">
                              {getCardNumberLen(idx)}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Name and Expiration date */}
                    <div className="ui-card__row">
                      <div className="ui-card__col-3">
                        <span id="ui-card__name" className="ui-card__name">
                          {creditCard.holderName}
                        </span>
                      </div>
                      <div className="ui-card__col-1">
                        <div
                          id="ui-card__expiration"
                          className="ui-card__expiration"
                        >
                          <span>
                            {creditCard.expiryYear || '0000'}/
                            {(creditCard.expiryMonth || '00')
                              .toString()
                              .padStart(2, '0')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Back */}
                  <div className="ui-card__container ui-card__back">
                    <div className="ui-card__background"></div>
                    <div className="ui-card__background-overlay"></div>
                    {/* Magnetic */}
                    <div className="ui-card__row">
                      <div className="ui-card__col-4">
                        <div className="ui-card__magnetic"></div>
                      </div>
                    </div>
                    {/* Signature */}
                    <div className="ui-card__row">
                      <div className="ui-card__col-3">
                        <div className="ui-card__signature">
                          {Array.from({ length: 6 }, (_, idx) => (
                            <span key={idx}></span>
                          ))}
                        </div>
                      </div>
                      <div className="ui-card__col-1">
                        <div
                          id="ui-card__security-back"
                          className="ui-card__security ui-card__security-back"
                        >
                          {Array.from({ length: 3 }, (_, idx) => (
                            <span key={idx} className="ui-card__point"></span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardDiv;

import React, { FC } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { useLogout } from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import { useFetchUser } from '../../hooks/useFetchUser';

const Header: FC = () => {
  const user = useFetchUser();
  const logout = useLogout();
  const navigate = useNavigate();

  const handleNavigate = (event: React.MouseEvent, path: string) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <Navbar variant="dark" expand="lg" style={{ backgroundColor: '#1f9bd6' }}>
      <Container fluid>
        <Navbar.Brand href="/">STAR ACCOUNT</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {window.innerWidth < 767 &&
              (constants.menuItems || []).map((item) => (
                <Nav.Link
                  key={item.label}
                  onClick={(event) => handleNavigate(event, item.path)}
                  href="#"
                >
                  {item.icon} {item.label}
                </Nav.Link>
              ))}
          </Nav>

          <Nav>
            <NavDropdown title="Contact STAR" id="contact-nav-dropdown">
              <NavDropdown.Item href="https://accounts.starsacramento.org/contact/#general">
                General Inquiry
              </NavDropdown.Item>
              <NavDropdown.Item href="https://accounts.starsacramento.org/contact/#support">
                Tech Support
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          {user.data && user.data.id && (
            <Nav>
              <button
                id={'logout-button'}
                onClick={logout}
                className="btn btn-primary ml-2 btn-sac"
              >
                Logout
              </button>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

import { FC, useState, useEffect } from 'react';
import { Tabs, Form, Input, Button, Card, Alert, Result } from 'antd';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { mutationNotification, useGet, usePut } from '../../hooks/useApi';
import PageTitle from '../../layout/PageTitle/PageTitle';
import { Col, Row } from 'react-bootstrap';
import { PrimaryGuardian as PrimaryGuardianModel } from '../../models/primary-guardian.model';

const PrimaryGuardian: FC = () => {
  const primaryGuardian = useGet<PrimaryGuardianModel>(
    '/v1/auth/dashboard/primary-guardian'
  );
  const mutation = usePut<any, any>('/v1/auth/dashboard/primary-guardian');

  const [form] = Form.useForm();
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    if (primaryGuardian.data) {
      form.setFieldsValue(primaryGuardian.data);
    }
  }, [primaryGuardian.data]);

  useEffect(
    () =>
      mutationNotification(mutation, {
        success: {
          title: 'Update Successful',
          description:
            'The primary guardian information has been updated successfully.',
        },
        error: {
          title: 'Update Failed',
        },
      }),
    [mutation.isSuccess, mutation.isError]
  );

  useEffect(() => {
    primaryGuardian.refetch();
  }, [mutation.isSuccess]);

  const onFinish = (values: Partial<PrimaryGuardianModel>) => {
    mutation.mutate(values);
    setFormError(null);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    setFormError(
      'Please double-check the entire form for missing required information and then try saving the information again.'
    );
  };

  const Box = () => (
    <div className={'pt-4'}>
      <div
        style={{
          backgroundColor: '#eadef3',
          padding: '1rem',
          border: '1px solid rgba(10,10,10,.25)',
          fontSize: '16px',
        }}
      >
        <p className={'pb-3'}>
          STAR considers the primary guardian to be the individual financially
          responsible for this account.
        </p>
        <p className={'pb-3'}>
          STAR expects that they will likely be the person that makes account
          changes, enrollment decisions, and signs paperwork.
        </p>
        <p className={'pb-3'}>
          For account related information this person will always be the first
          contacted.
        </p>
      </div>
    </div>
  );

  const OnError = () => (
    <Result
      status="500"
      title="Error"
      subTitle="Error while loading the page"
      extra={
        <Button onClick={() => primaryGuardian.refetch()} type="primary">
          Try Again
        </Button>
      }
    />
  );

  const PrimaryGuardianTab = () => (
    <Card
      loading={primaryGuardian.isLoading || mutation.isLoading}
      title="Primary Guardian"
      bordered={false}
    >
      <div id={'PrimaryGuardianTab'}>
        <Form
          form={form}
          name="primary"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input placeholder="First Name" id={'firstName_primary'} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Last Name is required' }]}
          >
            <Input placeholder="Last Name" id={'lastName_primary'} />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, message: 'E-mail is required' }]}
          >
            <Input placeholder="E-mail" id={'email_primary'} />
          </Form.Item>
          <Form.Item
            label="Driver's License"
            name="driversLicense"
            rules={[
              { required: true, message: "Driver's License is required" },
            ]}
          >
            <Input
              placeholder="Driver's License"
              id={'driversLicense_primary'}
            />
          </Form.Item>
          <Form.Item
            label="Cell Phone"
            name="cellPhone"
            rules={[
              {
                required: true,
                pattern: /^\d{3}[- ]\d{3}[- ]\d{4}$/,
                message: 'Required Format: XXX-XXX-XXXX',
              },
            ]}
          >
            <Input placeholder="Cell Phone" id={'cellPhone_primary'} />
          </Form.Item>
          <Form.Item
            label="Home Phone"
            name="homePhone"
            rules={[
              {
                required: true,
                pattern: /^\d{3}[- ]\d{3}[- ]\d{4}$/,
                message: 'Required Format: XXX-XXX-XXXX',
              },
            ]}
          >
            <Input placeholder="Home Phone" id={'homePhone_primary'} />
          </Form.Item>
          {formError && <Alert message={formError} type="error" showIcon />}
          <Button block size={'large'} type="primary" htmlType="submit">
            Save/Update
          </Button>
        </Form>
      </div>
    </Card>
  );

  const ResidenceTab = () => (
    <Card
      id={'ResidenceTab'}
      loading={primaryGuardian.isLoading || mutation.isLoading}
      title="Residence"
      bordered={false}
    >
      <Form
        form={form}
        name="residence"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Home Address"
          name="address"
          rules={[{ required: true, message: 'Home Address is required' }]}
        >
          <Input id="address" placeholder="Home Address" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'City is required' }]}
        >
          <Input id="city" placeholder="City" />
        </Form.Item>
        <Form.Item
          label="Zip Code"
          name="zip"
          rules={[{ required: true, message: 'Zip Code is required' }]}
        >
          <Input id="zip" placeholder="Zip Code" />
        </Form.Item>
        {formError && <Alert message={formError} type="error" showIcon />}
        <Button block size={'large'} type="primary" htmlType="submit">
          Save/Update
        </Button>
      </Form>
    </Card>
  );

  const EmployerTab = () => (
    <Card
      id={'EmployerTab'}
      loading={primaryGuardian.isLoading || mutation.isLoading}
      title="Employer"
      bordered={false}
    >
      <Form
        form={form}
        name="employer"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Employer"
          name="employer"
          rules={[{ required: true, message: 'Employer is required' }]}
        >
          <Input id="employer_name" placeholder="Employer" />
        </Form.Item>
        <Form.Item
          label="Position"
          name="position"
          rules={[{ required: true, message: 'Position is required' }]}
        >
          <Input id="position" placeholder="Position" />
        </Form.Item>
        <Form.Item
          label="Employer's Phone"
          name="employerPhone"
          rules={[
            {
              required: true,
              pattern: /^\d{3}[- ]\d{3}[- ]\d{4}$/,
              message: 'Required Format: XXX-XXX-XXXX',
            },
          ]}
        >
          <Input id="employerPhone" placeholder="Employer's Phone" />
        </Form.Item>
        <Form.Item
          label="Employer's Address"
          name="employerAddress"
          rules={[
            { required: true, message: "Employer's Address is required" },
          ]}
        >
          <Input id="employerAddress" placeholder="Employer's Address" />
        </Form.Item>
        {formError && <Alert message={formError} type="error" showIcon />}
        <Button block size={'large'} type="primary" htmlType="submit">
          Save/Update
        </Button>
      </Form>
    </Card>
  );

  return (
    <SidebarPage>
      <PageTitle>
        {primaryGuardian.isLoading
          ? 'Loading...'
          : (primaryGuardian.data?.lastName || '') +
            ' Family Account: Primary Guardian'}
      </PageTitle>

      <Row>
        <Col md={6}>
          {primaryGuardian.isError && <OnError />}
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Primary Guardian" key="1">
              <PrimaryGuardianTab />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Residence"
              disabled={!primaryGuardian.data?.id}
              key="2"
            >
              <ResidenceTab />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Employer"
              disabled={!primaryGuardian.data?.id}
              key="3"
            >
              <EmployerTab />
            </Tabs.TabPane>
          </Tabs>
        </Col>
        <Col md={6} style={{ paddingTop: '36px' }}>
          <Box />
        </Col>
      </Row>
    </SidebarPage>
  );
};

export default PrimaryGuardian;

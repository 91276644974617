import React, { FC } from 'react';

import './ChooseCreditCard.css';
import CreditCardList from './CreditCardList';

const ChooseCreditCard: FC<{
  customer: any;
  creditCards: any[];
  creditCardAdded: () => void;
  creditCardUpdated: () => void;
  creditCardDeleted: () => void;
}> = ({
  customer,
  creditCards,
  creditCardAdded,
  creditCardUpdated,
  creditCardDeleted,
}) => {
  return (
    <div>
      <p className={'pb-3'}>
        Please select the card you would like to use for automatic payments. If
        there are no cards listed, you will first have to add a card.
      </p>

      <CreditCardList
        creditCards={creditCards}
        customer={customer}
        creditCardAdded={creditCardAdded}
        creditCardUpdated={creditCardUpdated}
        creditCardDeleted={creditCardDeleted}
      />
    </div>
  );
};

export default ChooseCreditCard;

import React, { FC, ReactNode } from 'react';

interface PageProps {
  children: ReactNode;
}

const PageTitle: FC<PageProps> = ({ children }) => (
  <div>
    <div className={'pb-4'}>
      <h2 style={{ color: '#703895', fontSize: '2.5rem' }}>{children}</h2>
    </div>
    <hr className={'pb-4'} />
  </div>
);

export default PageTitle;

import React, { FC, ReactNode } from 'react';
import './Page.css';

interface PageProps {
  children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => {
  return <div className="page">{children}</div>;
};

export default Page;

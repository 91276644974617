import React, { FC, useEffect } from 'react';
import { Modal, Form, DatePicker, message } from 'antd';
import CreditCardDiv from '../../../components/CreditCardDiv';
import { mutationNotification, usePut } from '../../../hooks/useApi';

const UpdateCardModal: FC<{
  visible: boolean;
  onCancel: () => void;
  selectedPaymentMethod: any;
  onUpdateCardExpiry: () => void;
  customerName: string;
}> = ({
  visible,
  onCancel,
  customerName,
  selectedPaymentMethod,
  onUpdateCardExpiry,
}) => {
  const [form] = Form.useForm();

  const updateCardExpiryPut = usePut<any, any>(
    '/v1/auth/dashboard/auto-pay/updatePaymentMethodExpiry/' +
      selectedPaymentMethod.id
  );

  useEffect(() => {
    mutationNotification(updateCardExpiryPut, {
      success: {
        title: 'Success',
        description: 'Payment method update successfully',
      },
      error: {
        title: 'Error updating payment method',
      },
    });

    if (updateCardExpiryPut.isSuccess) {
      onUpdateCardExpiry();
    }
  }, [updateCardExpiryPut.isSuccess, updateCardExpiryPut.isError]);

  const updateCardExpiry = async (values: any) => {
    const expirationDate = values.expiration;

    if (expirationDate.isBefore(new Date())) {
      message.error('Expiration date must be in the future');
      return;
    }

    updateCardExpiryPut.mutate({
      expirationDate: expirationDate.toISOString(),
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
      okText={'Update'}
      title={`Update Card Expiration Date`}
    >
      <div className={'pt-3'}>
        <Form form={form} onFinish={updateCardExpiry}>
          <Form.Item>
            {selectedPaymentMethod && (
              <CreditCardDiv
                creditCard={{
                  holderName:
                    selectedPaymentMethod.billing_details?.name || customerName,
                  number: `**** **** **** ${selectedPaymentMethod.card.last4}`,
                  expiryMonth: selectedPaymentMethod.card.exp_month,
                  expiryYear: selectedPaymentMethod.card.exp_year,
                  ccv: '',
                  cpfCnpj: '',
                  expiryDate: `${selectedPaymentMethod.card.exp_month}/${selectedPaymentMethod.card.exp_year}`,
                  brand: selectedPaymentMethod.card.brand,
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Expiration Date"
            name="expiration"
            rules={[
              { required: true, message: 'Please input the expiration date!' },
            ]}
          >
            <DatePicker className={'w-100'} picker="month" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateCardModal;

import React, { FC, ReactNode } from 'react';
import './Content.css';

interface PageProps {
  children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => {
  return <div className="content">{children}</div>;
};

export default Page;

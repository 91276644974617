import { FC, useState } from 'react';
import {
  Button,
  Card,
  Skeleton,
  notification,
  Result,
  Popconfirm,
  Modal,
} from 'antd';
import { getErrorMessage, useDelete, useGet } from '../../../../hooks/useApi';
import { Col, Row } from 'react-bootstrap';
import './List.css';
import { UserAddOutlined } from '@ant-design/icons';
import { Medication } from '../../../../models/medication.model';
import { Student } from '../../../../models/student.model';
import StudentMedication from './StudentMedication';

interface StudentMedicationListProps {
  student: Partial<Student> & { id: number };
}

const StudentMedicationList: FC<StudentMedicationListProps> = ({ student }) => {
  const studentId = student.id;

  const { data, isLoading, isError, refetch } = useGet<Medication[]>(
    `/v1/auth/dashboard/medications/student/${studentId}`
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState<
    Partial<Medication>
  >({});

  const onNew = () => {
    setSelectedMedication({});
    setIsModalOpen(true);
  };

  const onEdit = (medication: Medication) => {
    setSelectedMedication(medication);
    setIsModalOpen(true);
  };

  const Content = () => {
    const OnEmpty = () => (
      <Result
        status="404"
        title="No medications added yet"
        extra={
          <Button onClick={() => onNew()} type="primary">
            Add Medication
          </Button>
        }
      />
    );

    const OnError = () => (
      <Result
        status="500"
        title="Error"
        subTitle="Error while loading the page"
        extra={
          <Button onClick={() => refetch()} type="primary">
            Try Again
          </Button>
        }
      />
    );

    const CardExtra: FC<{ medication: Medication }> = ({ medication }) => {
      const deleteMutation = useDelete(
        `/v1/auth/dashboard/medication/${medication.id}`,
        {
          onSuccess: () => {
            refetch();
            notification.success({
              message: 'Success',
              description: 'Medication deleted successfully',
            });
          },
          onError: (err) =>
            notification.error({
              message: 'Error',
              description: getErrorMessage(err.response),
            }),
        }
      );

      const OnDelete = () => {
        deleteMutation.mutate();
      };

      return (
        <>
          <Button type={'link'} onClick={() => onEdit(medication)}>
            Edit
          </Button>

          <Popconfirm
            title="Delete"
            description="Are you sure to delete this?"
            onConfirm={() => OnDelete()}
            okText="Yes"
            cancelText="No"
          >
            <Button type={'link'} style={{ color: 'red' }}>
              Delete
            </Button>
          </Popconfirm>
        </>
      );
    };

    if (isLoading) {
      return <Skeleton active />;
    }

    if (isError) {
      return OnError();
    }

    if ((data || []).length <= 0) {
      return OnEmpty();
    }

    return (
      <>
        <Row className="pt-4">
          {data?.map((medication) => (
            <Col key={medication.id} md={4} className={'pb-4'}>
              <Card
                className={'medication-card'}
                title={medication.name}
                extra={<CardExtra medication={medication} />}
              >
                <p>Dosage</p>
                <h5>{medication.dosage}</h5>

                <p>Taken For</p>
                <h5>{medication.takenFor}</h5>

                <p>Prescibed By</p>
                <h5>{medication.doctor}</h5>

                <p>Taken At STAR By</p>
                <h5>{medication.atStar ? 'Yes' : 'No'}</h5>
              </Card>
            </Col>
          ))}

          <Col md={4}>
            <Card>
              <Result
                icon={<UserAddOutlined />}
                title="Medication"
                style={{ padding: '72px' }}
                subTitle=""
                extra={[
                  <Button onClick={() => onNew()} type="primary" key="console">
                    Add New
                  </Button>,
                ]}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  const EditModal = () => {
    const handleOk = () => {
      refetch();
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <Modal
        title={selectedMedication?.id ? 'Edit Medication' : 'New Medication'}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <div className={'pt-3'}>
          <StudentMedication
            studentId={student.id}
            medication={selectedMedication}
            updated={handleOk}
            created={handleOk}
          />
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Content />
      <EditModal />
    </>
  );
};

export default StudentMedicationList;

import React, { FC, useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CreditCardDiv from '../../../../components/CreditCardDiv';
import { mutationNotification, usePost } from '../../../../hooks/useApi';
import { Col, Row } from 'react-bootstrap';
import { countries } from 'countries-list';

const AddCardModal: FC<{
  visible: boolean;
  onCancel: () => void;
  onAddCard: () => void;
  customerName: string;
}> = ({ visible, onCancel, onAddCard, customerName }) => {
  const stripe = useStripe();
  const elements = useElements();

  const countryList: { value: string; label: string }[] = Object.entries(
    countries
  ).map(([key, { name }]) => ({
    value: key,
    label: name,
  }));

  const [cardInfo, setCardInfo] = useState({
    holderName: customerName || '',
    cardNumber: '**** **** **** ****',
    expiryMonth: '',
    expiryYear: '',
    expiryDate: 'MM/YY',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    billingCountry: 'US',
    email: '',
    phone: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);

  const addPaymentMethodPost = usePost<any, any>(
    '/v1/auth/dashboard/auto-pay/addPaymentMethod'
  );

  useEffect(() => {
    mutationNotification(addPaymentMethodPost, {
      success: {
        title: 'Success',
        description: 'Payment method added successfully',
      },
      error: {
        title: 'Error adding payment method',
      },
    });

    if (addPaymentMethodPost.isSuccess) {
      onAddCard();
    }
  }, [addPaymentMethodPost.isSuccess, addPaymentMethodPost.isError]);

  // Form validation effect
  useEffect(() => {
    const {
      holderName,
      email,
      phone,
      billingAddress,
      billingCity,
      billingState,
      billingZip,
      billingCountry,
    } = cardInfo;

    if (
      holderName.trim() &&
      email.trim() &&
      phone.trim() &&
      billingAddress.trim() &&
      billingCity.trim() &&
      billingState.trim() &&
      billingZip.trim() &&
      billingCountry.trim() &&
      cardComplete
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [cardInfo, cardComplete]);

  const addCard = async () => {
    if (!stripe || !elements) {
      message.error('Stripe has not loaded yet');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
      billing_details: {
        name: cardInfo.holderName,
        email: cardInfo.email,
        phone: cardInfo.phone,
        address: {
          line1: cardInfo.billingAddress,
          city: cardInfo.billingCity,
          state: cardInfo.billingState,
          postal_code: cardInfo.billingZip,
          country: cardInfo.billingCountry,
        },
      },
    });

    if (error) {
      message.error(error.message || 'Error creating payment method');
      return;
    }

    addPaymentMethodPost.mutate({
      paymentMethodId: paymentMethod.id,
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={addCard}
      title="Add a Card"
      okText={'Add Card'}
      okButtonProps={{ disabled: !isFormValid }}
    >
      <div className={'pt-3'}>
        <Form layout="vertical">
          {/* Credit Card Div */}
          <Form.Item>
            <CreditCardDiv
              creditCard={{
                holderName: cardInfo.holderName,
                number: cardInfo.cardNumber,
                expiryMonth: cardInfo.expiryMonth,
                expiryYear: cardInfo.expiryYear,
                ccv: '',
                cpfCnpj: '',
                expiryDate: cardInfo.expiryDate,
              }}
            />
          </Form.Item>

          {/* Cardholder name input */}
          <Form.Item label="Cardholder Name">
            <Input
              value={cardInfo.holderName}
              onChange={(e) =>
                setCardInfo({ ...cardInfo, holderName: e.target.value })
              }
              placeholder="Cardholder Name"
            />
          </Form.Item>

          <Row>
            <Col md={6}>
              {/* Email input */}
              <Form.Item label="Email">
                <Input
                  value={cardInfo.email}
                  onChange={(e) =>
                    setCardInfo({ ...cardInfo, email: e.target.value })
                  }
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              {/* Phone input */}
              <Form.Item label="Phone">
                <Input
                  value={cardInfo.phone}
                  onChange={(e) =>
                    setCardInfo({ ...cardInfo, phone: e.target.value })
                  }
                  placeholder="Phone"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Billing Address */}
          <Form.Item label="Billing Address">
            <Input
              value={cardInfo.billingAddress}
              onChange={(e) =>
                setCardInfo({ ...cardInfo, billingAddress: e.target.value })
              }
              placeholder="Street Address"
            />
          </Form.Item>

          <Row>
            <Col md={4}>
              {/* Billing City */}
              <Form.Item label="City">
                <Input
                  value={cardInfo.billingCity}
                  onChange={(e) =>
                    setCardInfo({ ...cardInfo, billingCity: e.target.value })
                  }
                  placeholder="City"
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              {/* Billing State */}
              <Form.Item label="State">
                <Input
                  value={cardInfo.billingState}
                  onChange={(e) =>
                    setCardInfo({ ...cardInfo, billingState: e.target.value })
                  }
                  placeholder="State"
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              {/* Billing Zip Code */}
              <Form.Item label="Postal Code">
                <Input
                  value={cardInfo.billingZip}
                  onChange={(e) =>
                    setCardInfo({ ...cardInfo, billingZip: e.target.value })
                  }
                  placeholder="Postal Code"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Billing Country */}
          <Form.Item label="Country">
            <Select
              value={cardInfo.billingCountry}
              onChange={(value) =>
                setCardInfo({ ...cardInfo, billingCountry: value })
              }
              placeholder="Select Country"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={countryList}
            />
          </Form.Item>

          <Form.Item label="Credit Card">
            <div
              style={{
                border: '1px solid #d9d9d9',
                padding: '6px 11px',
                borderRadius: '6px',
              }}
            >
              <CardElement
                options={{
                  hidePostalCode: true,
                }}
                onChange={(event) => setCardComplete(event.complete)}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCardModal;

// src/hooks/useFetchUser.ts
import { useGet, UseGetOptions } from './useApi';
import { useContextValue } from '../Context';

export const useFetchLedgerBalance = (options?: UseGetOptions) => {
  const { setState } = useContextValue();

  return useGet<number>('/v1/auth/dashboard/ledger-lines/balance', {
    ...options,
    queryKey: ['/v1/auth/dashboard/ledger-lines/balance'],
    enabled: !!localStorage.getItem('user'),
    onSuccess: (data) => {
      setState((prevState) => ({ ...prevState, ledgerBalance: data }));
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, ledgerBalance: 0 }));
    },
  });
};

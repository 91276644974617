// src/hooks/useFetchUser.ts
import { useGet, UseGetOptions } from './useApi';
import { useContextValue } from '../Context';
import { User } from '../models/user.model';

export const useFetchUser = (options?: UseGetOptions) => {
  const { setState } = useContextValue();

  return useGet<User>('/v1/auth/me', {
    ...options,
    queryKey: ['user'],
    enabled: !!localStorage.getItem('user'),
    onSuccess: (data) => {
      setState((prevState) => ({ ...prevState, user: data }));
    },
    onError: () => {
      localStorage.removeItem('user');
      setState((prevState) => ({ ...prevState, user: null }));
    },
  });
};

import React, { FC, useEffect } from 'react';
import { Card, Button } from 'antd';
import CreditCardDiv from '../../../components/CreditCardDiv';
import Swal from 'sweetalert2';
import { mutationNotification, useDelete } from '../../../hooks/useApi';

const isCardExpired = (exp_month: number, exp_year: number) => {
  const today = new Date();
  const expiryDate = new Date(exp_year, exp_month - 1, 1);
  return expiryDate < today;
};

const CreditCardItem: FC<{
  paymentMethod: any;
  index: number;
  customerName: string;
  handleUpdateCard: (paymentMethod: any) => void;
  handleSelectCard: (paymentMethod: any) => void;
  creditCardDeleted: () => void;
}> = ({
  paymentMethod,
  index,
  customerName,
  handleUpdateCard,
  handleSelectCard,
  creditCardDeleted,
}) => {
  const billing_details = paymentMethod.billing_details;
  const card = paymentMethod.card;
  const expired = isCardExpired(card.exp_month, card.exp_year);

  const deletePaymentMethodPost = useDelete<any>(
    '/v1/auth/dashboard/auto-pay/deletePaymentMethod/' + paymentMethod.id
  );

  useEffect(() => {
    mutationNotification(deletePaymentMethodPost, {
      success: {
        title: 'Success',
        description: 'Payment method deleted successfully',
      },
      error: {
        title: 'Error deleting payment method',
      },
    });

    if (deletePaymentMethodPost.isSuccess) {
      creditCardDeleted();
    }
  }, [deletePaymentMethodPost.isSuccess, deletePaymentMethodPost.isError]);

  const handleDeleteCard = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this card?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      confirmButtonColor: 'red',
    });

    if (result.isConfirmed) {
      deletePaymentMethodPost.mutate();
    }
  };

  return (
    <>
      <Card
        title={`Card #${index + 1}`}
        extra={
          <Button type={'text'} danger onClick={handleDeleteCard} block={true}>
            Delete
          </Button>
        }
      >
        <CreditCardDiv
          creditCard={{
            holderName: billing_details.name || customerName,
            number: `**** **** **** ${card.last4}`,
            expiryMonth: card.exp_month,
            expiryYear: card.exp_year,
            ccv: '',
            cpfCnpj: '',
            expiryDate: `${card.exp_month}/${card.exp_year}`,
            brand: card.brand,
          }}
        />

        {expired ? (
          <div className={'mt-2'}>
            <p style={{ color: 'red' }} className={'text-center pb-2'}>
              <small>This Credit Card is Expired</small>
            </p>
            <Button
              onClick={() => handleUpdateCard(paymentMethod)}
              block={true}
            >
              Update
            </Button>
          </div>
        ) : (
          <div className={'mt-4'}>
            <Button
              type="primary"
              onClick={() => handleSelectCard(paymentMethod)}
              block={true}
            >
              Select
            </Button>
          </div>
        )}
      </Card>
    </>
  );
};

export default CreditCardItem;

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useEffect, useState } from 'react';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import PageTitle from '../../layout/PageTitle/PageTitle';
import { useFetchUser } from '../../hooks/useFetchUser';
import StudentList from '../Student/List';
import { Button, Card, Modal, Result, Skeleton } from 'antd';
import { CardTitle, Col, Row } from 'react-bootstrap';

import './HomePage.css';
import ChangeEmail from './modals/ChangeEmail';
import ChangePassword from './modals/ChangePassword';
import Ledger from './Ledger';
import UpcomingNSDCamps from './components/UpcomingNSDCamps';

const HomePage: FC = () => {
  const user = useFetchUser();
  const [changeEmailVisible, setChangeEmailVisible] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);

  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paymentModalContent, setPaymentModalContent] = useState('cancel');

  const [primaryGuardianId, setPrimaryGuardianId] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (window.location.search === '?payment=success') {
      setPaymentModalVisible(true);
      setPaymentModalContent('success');
    }

    if (window.location.search === '?payment=cancel') {
      setPaymentModalVisible(true);
      setPaymentModalContent('cancel');
    }
  }, []);

  useEffect(() => {
    if (
      user.data?.PrimaryGuardian?.id &&
      user.data?.PrimaryGuardian?.id != primaryGuardianId
    ) {
      setPrimaryGuardianId(+user.data?.PrimaryGuardian?.id);
    }
  }, [user.data]);

  if (user.isLoading) {
    return (
      <SidebarPage>
        <PageTitle>Loading...</PageTitle>
        <Skeleton active={true} />
      </SidebarPage>
    );
  }

  if (!primaryGuardianId) {
    return (
      <SidebarPage>
        <PageTitle>
          {`${user.data?.PrimaryGuardian?.lastName || ''} Family Account`}
        </PageTitle>

        <Row>
          <Col md={4}>
            <Card className={'home-page-card'} title={'Primary Guardian'}>
              <div>
                Name
                <br />
                <a href={'/primary'}>Add Primary Information</a>
              </div>

              <hr />

              <div>
                Account Email
                <h5>{user.data?.email}</h5>
              </div>
            </Card>
          </Col>
          <Col md={8}>
            <div
              id={'box'}
              style={{
                backgroundColor: '#eadef3',
                padding: '1rem',
                border: '1px solid rgba(10,10,10,.25)',
                fontSize: '16px',
              }}
            >
              <div
                style={{
                  marginBottom: '16px',
                  fontSize: '24px',
                  color: '#703895',
                }}
              >
                Welcome to your new STAR Family Dashboard!
              </div>
              <p>
                To get started, please click Add Primary Information in the
                primary guardian card to the left and create your primary
                guardian.
              </p>

              <p>
                After you have created your primary guardian, you will be able
                to add your additional guardians, emergency contacts, and your
                students.
              </p>

              <p>
                You will not be able to enroll your students until you have
                completed all your family information.
              </p>

              <p>
                If you do not add family information to this account in the next
                30 days, it will automatically be deleted.
              </p>
            </div>
          </Col>
        </Row>
      </SidebarPage>
    );
  }

  if (primaryGuardianId) {
    return (
      <SidebarPage>
        <PageTitle>
          {`${user.data?.PrimaryGuardian?.lastName || ''} Family Account`}
        </PageTitle>

        <UpcomingNSDCamps />
        <Row className={'mb-4'}>
          <Col md={6}>
            <Card className={'home-page-card'} title={'Primary Guardian'}>
              <div>
                Name
                <h5>
                  {user.data?.PrimaryGuardian?.firstName}{' '}
                  {user.data?.PrimaryGuardian?.lastName || ''}
                </h5>
                <a href={'/primary'}>Edit Primary Information</a>
              </div>

              <hr />

              <div>
                Account Email
                <h5>{user.data?.PrimaryGuardian?.email}</h5>
                <div>
                  <a
                    href={'javascript:void(0)'}
                    onClick={() => setChangeEmailVisible(true)}
                  >
                    Change Email
                  </a>
                  <ChangeEmail
                    visible={changeEmailVisible}
                    onVisibleChange={setChangeEmailVisible}
                  />
                </div>
                <div>
                  <a
                    href={'javascript:void(0)'}
                    onClick={() => setChangePasswordVisible(true)}
                  >
                    Change Password
                  </a>
                  <ChangePassword
                    visible={changePasswordVisible}
                    onVisibleChange={setChangePasswordVisible}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col md={6}>
            <Ledger />
          </Col>
        </Row>

        <Card>
          <CardTitle>Students</CardTitle>
        </Card>
        <StudentList schedule={false} mode={'component'} />

        <Modal
          title={'Payment'}
          open={paymentModalVisible}
          onCancel={() => setPaymentModalVisible(false)}
          footer={null}
        >
          <Result
            status={paymentModalContent === 'success' ? 'success' : 'warning'}
            title={
              paymentModalContent === 'success' ? 'Success!' : 'Payment Failed'
            }
            subTitle={
              paymentModalContent === 'success'
                ? 'Your payment was successful.'
                : 'Your payment was not successful. Please Try Again.'
            }
            extra={[
              <Button onClick={() => setPaymentModalVisible(false)} key="buy">
                Close
              </Button>,
            ]}
          />
        </Modal>
      </SidebarPage>
    );
  }
};

export default HomePage;

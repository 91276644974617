import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import PageTitle from '../../layout/PageTitle/PageTitle';
import { Button, Tabs } from 'antd';
import { useGet } from '../../hooks/useApi';
import StudentInformation from './Tabs/StudentInformation';
import { Student as StudentModel } from '../../models/student.model';
import StudentAdditionalGuardians from './Tabs/StudentAdditionalGuardians';
import StudentEmergencyContacts from './Tabs/StudentEmergencyContacts';
import StudentMedicalInformation from './Tabs/StudentMedicalInformation';
import StudentSharingInformation from './Tabs/StudentSharingInformation';
import StudentMedicationList from './Tabs/StudentMedication/List';

const Student: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [data, setData] = useState<Partial<StudentModel>>({});

  const api = useGet<any>('/v1/auth/dashboard/student/' + id, {
    enabled: !!id,
  });

  useEffect(() => {
    if (api.isSuccess) {
      setData(api.data);
    }
  }, [api.isSuccess, api.data]);

  const onCreate = (student: any) => {
    navigate(`/student/${student.id}`);
  };

  const OnError = () => (
    <div className={'alert alert-danger'}>
      <table className={'w-100'}>
        <tr>
          <td>Error while loading the page</td>
          <td className={'text-right'} style={{ width: '100px' }}>
            <Button type={'primary'} onClick={() => api.refetch()}>
              Reload
            </Button>
          </td>
        </tr>
      </table>
    </div>
  );

  return (
    <SidebarPage>
      <PageTitle>
        {api.isLoading
          ? 'Loading...'
          : data && data.id
            ? `${data.firstName} ${data.lastName}`
            : `New Student`}
      </PageTitle>

      {id && api.isError && <OnError />}

      {((id && api.isSuccess) || !id) && (
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Student Information" key="1">
            <StudentInformation student={data} created={onCreate} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Additional Guardians" key="2" disabled={!data.id}>
            <StudentAdditionalGuardians student={data} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Emergency Contacts" key="3" disabled={!data.id}>
            <StudentEmergencyContacts student={data} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Medical Information" key="4" disabled={!data.id}>
            <StudentMedicalInformation student={data} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Medication" key="5" disabled={!data.id}>
            <StudentMedicationList student={data} />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab="Parent Sharing Information"
            key="6"
            disabled={!data.id}
          >
            <StudentSharingInformation student={data} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </SidebarPage>
  );
};

export default Student;
